import { Link } from "react-router-dom";
import { route } from "../../core/routing";

const defaultData = {
  completion: 0,
};

// TODO Disable function
const VariantRow = ({ data, title, disabled = false, link, variantId }) => {
  const rowData = {
    ...defaultData,
    ...data,
  };
  return (
    <tr>
      <th scope="row" className="text-start ps-3">
        {title}
      </th>
      <td className="text-end">Progress:</td>
      <td className="w-50">
        <div className="progress">
          <div
            className="progress-bar bg-primary"
            role="progressbar"
            style={{ width: `${rowData.completion}%` }}
          >
            {`${rowData.completion}%`}
          </div>
        </div>
      </td>
      {/* {rowData.modified ? (
        <td className="text-center">Last Edit: {rowData.modified}</td>
      ) : (
        <td className="text-center"></td>
      )} */}
      <td className="pe-3">
        <Link
          to={route(link, { variantId })}
          className="btn btn-sm btn-primary float-end"
        >
          Edit
        </Link>
      </td>
    </tr>
  );
};

export default VariantRow;
