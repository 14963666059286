import { useCallback, useState } from "react";
import ErrorAlert from "../../../components/errors/ErrorAlert";
import Spinner from "../../../components/Spinner";
import TableControl from "../../../components/TableControl";
import Title from "../../../components/Title";
import useFetch from "../../../core/hooks/useFetch";
import useTitle from "../../../core/hooks/useTitle";
import { fetchTranslations } from "../../../core/modules/translations/api";
import { Routes } from "../../../core/routing";
import { AdditiveTableCard } from "./AdditiveTableCard";
import { TranslationsTableCard } from "./TranslationsTableCard";

export const TranslationsOverview = () => {
  const [query, setQuery] = useState("");

  useTitle("Translations");

  const apiCall = useCallback(() => {
    return fetchTranslations(query);
  }, [query]);

  const { data, error, isLoading, refresh } = useFetch(apiCall);

  if (isLoading) {
    return <Spinner fill />;
  }

  if (error) {
    return <ErrorAlert error={error} />;
  }

  let allergenen = [];
  let additieven = [];
  let ingredienten = [];
  let extras = [];

  data.forEach((item) => {
    if (item.type === "Additive") {
      additieven.push(item);
    } else if (item.type === "Allergen") {
      allergenen.push(item);
    } else if (item.type === "Extra") {
      extras.push(item);
    } else {
      ingredienten.push(item);
    }
  });

  console.log(data)

  return (
    <>
      <Title title="Translations" />
      <TableControl
        name="Translation"
        link={Routes.Translations.Create}
        setQuery={setQuery}
        query={query}
      />
      <TranslationsTableCard
        data={ingredienten}
        refresh={refresh}
        title={"Ingrediënten"}
      />
      <TranslationsTableCard
        data={allergenen}
        refresh={refresh}
        title={"Allergenen"}
      />
      <AdditiveTableCard
        data={additieven}
        refresh={refresh}
        title={"Additieven"}
      />
      <TranslationsTableCard
        data={extras}
        refresh={refresh}
        title={"Extras"}
        pageSize={5}
      />
    </>
  );
};
