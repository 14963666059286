import { Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "../core/routing";
import Profile from "../pages/shared/Profile";

const ClientRouting = () => {
  return (
    <Switch>
      <Route path={Routes.Profile}>
        <Profile />
      </Route>

      {/* Dashboard */}
      <Route path={Routes.Dashboard}>
        <h1>Client</h1>
      </Route>

      <Redirect to={Routes.Dashboard} />
    </Switch>
  );
};
export default ClientRouting;
