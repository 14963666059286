import * as yup from "yup";
import useForm from "../../../core/hooks/useForm";
// import Button from "../Button";
// import FormTitle from "../FormTitle";
import Input from "../Input";

const defaultData = {
  name: "",
};

let schema = yup.object().shape({
  name: yup.string().required(),
});

export const GeneralInfoForm = ({
  initialData = {},
  onSubmit,
  onEdit,
  onDelete,
  disabled,
  loading,
}) => {
  const initial = {
    ...defaultData,
    ...initialData,
  };
  const { values, errors, handleChange, handleSubmit, isTouched } = useForm(
    schema,
    initial
  );

  const handleData = (values) => {
    console.log(values);
    // onSubmit(values);
  };

  return (
    <form onSubmit={handleSubmit(handleData)} noValidate>
      <div className="row">
        <div className="col border-end border-2">
          <Input
            label="Test"
            name="name"
            value={values.name}
            onChange={handleChange}
            error={errors.name}
            validated={isTouched}
            disabled={disabled || loading}
          />
        </div>
        <div className="col">
          <Input
            label="Test"
            name="name"
            value={values.name}
            onChange={handleChange}
            error={errors.name}
            validated={isTouched}
            disabled={disabled || loading}
          />
        </div>
      </div>
    </form>
  );
};
