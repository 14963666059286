import { Link } from "react-router-dom";
import { route, Routes } from "../../core/routing";

const UserRow = ({ user }) => {
  return (
    <tr>
      <th scope="row" className="text-start ps-3">
        {user.name}
      </th>
      <td>{user.company.name}</td>
      <td>{user.email}</td>
      <td>
        {user.role === "admin" && "Admin"}
        {user.role === "client" && "Client"}
        {user.role === "designer" && "Designer"}
      </td>
      <td className="pe-3">
        <Link
          to={route(Routes.Users.Edit, { id: user._id })}
          className="btn btn-sm btn-primary float-end"
        >
          Edit
        </Link>
      </td>
    </tr>
  );
};

export default UserRow;
