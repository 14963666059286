import { Pagination } from "./Pagination";

const TableControlPagination = ({ title, setPage, page, maxPages }) => {
  const decrease = () => {
    if (page !== 0) {
      setPage(page - 1);
    }
  };

  const increase = () => {
    if (page < maxPages) {
      setPage(page + 1);
    }
  };

  return (
    <div className='d-flex justify-content-between mb-3"'>
      <h4 className="my-auto fw-bold">{title}</h4>
      {maxPages > 1 && (
        <Pagination
          increase={() => increase()}
          decrease={() => decrease()}
          page={page}
          maxPages={maxPages}
        />
      )}
    </div>
  );
};

export default TableControlPagination;
