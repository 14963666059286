import { useCallback, useState } from "react";
import { useParams } from "react-router";
import ErrorAlert from "../../../components/errors/ErrorAlert";
import { ProjectActions } from "../../../components/projects/ProjectActions";
import ProjectData from "../../../components/projects/ProjectData";
import Spinner from "../../../components/Spinner";
import Title from "../../../components/Title";
import useFetch from "../../../core/hooks/useFetch";
import useTitle from "../../../core/hooks/useTitle";
import { fetchProject } from "../../../core/modules/projects/api";
import { VariantsView } from "../../shared/variants/VariantsView";

const ProjectDetail = () => {
  const { id } = useParams();

  const [open, setOpen] = useState(false);

  const apiCall = useCallback(() => {
    return fetchProject(id);
  }, [id]);

  const { data: project, error, isLoading, refresh, setData } = useFetch(
    apiCall
  );

  useTitle(project ? project.name : "Projects");

  const toggleOpen = () => {
    setOpen(!open);
  };

  if (isLoading) {
    return <Spinner fill size="3rem" />;
  }

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <>
      <Title title={project.name} back />
      <ProjectActions
        id={id}
        refresh={refresh}
        setData={setData}
        data={project}
        onClickAdd={toggleOpen}
      />
      <ProjectData data={project} />

      <VariantsView projectId={id} open={open} toggleOpen={toggleOpen} />
    </>
  );
};

export default ProjectDetail;
