import { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ErrorAlert from "../../../components/errors/ErrorAlert";
import { TranslationForm } from "../../../components/forms/Translations/TranslationForm";
import Spinner from "../../../components/Spinner";
import Title from "../../../components/Title";
import useAuthApi from "../../../core/hooks/useAuthApi";
import useFetch from "../../../core/hooks/useFetch";
import useTitle from "../../../core/hooks/useTitle";
import {
  fetchTranslation,
  updateTranslations,
} from "../../../core/modules/translations/api";
import { Routes } from "../../../core/routing";

export const TranslationEdit = () => {
  const withAuth = useAuthApi();
  const history = useHistory();
  const [isLoadingPost, setIsLoadingPost] = useState();
  const [postError, setPostError] = useState();
  const { id } = useParams();

  const apiCall = useCallback(() => {
    return fetchTranslation(id);
  }, [id]);

  const { data: translation, error, isLoading } = useFetch(apiCall);

  useTitle("Edit keyword");

  const handleSubmit = (data) => {
    setIsLoadingPost(true);
    withAuth(updateTranslations(data))
      .then(() => {
        history.push(Routes.Translations.Index);
      })
      .catch((err) => {
        setPostError(err);
        setIsLoadingPost(false);
      });
  };

  if (isLoading) {
    return <Spinner fill size="3rem" />;
  }

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <>
      <Title title="Edit Keyword" back />
      <ErrorAlert error={postError} />
      <TranslationForm
        initialData={translation}
        onSubmit={handleSubmit}
        loading={isLoadingPost}
      />
    </>
  );
};
