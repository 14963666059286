import { useState } from "react";
import { Link } from "react-router-dom";
import useAuthApi from "../../core/hooks/useAuthApi";
import { deleteTranslation } from "../../core/modules/translations/api";
import { route, Routes } from "../../core/routing";
import Button from "../forms/Button";
import Modal from "../Modal";

export const TranslationRow = ({ translation, refresh, additive }) => {
  const [open, setOpen] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState();

  const withAuth = useAuthApi();

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleDelete = () => {
    setIsLoadingDelete(true);
    withAuth(deleteTranslation(translation._id)).then(() => {
      refresh();
    });
  };

  return (
    <>
      <tr>
        <th scope="row" className="text-start ps-3 w-25">
          {translation.dutch}
        </th>
        <td className="pe-3 w-25">{translation.french}</td>
        <td className="pe-3 w-25">{translation.german}</td>
        {additive && (
          <th style={{ width: "8%" }}>{`E ${translation.number}`}</th>
        )}
        <td className="pe-3 text-end fit-content">
          <Link
            to={route(Routes.Translations.Edit, { id: translation._id })}
            className="btn btn-sm btn-warning me-2"
          >
            <i className="bi bi-pencil-square pe-2"></i>
            Edit
          </Link>
          <Button
            extraStyle="btn-sm"
            color="danger"
            onClick={toggleOpen}
            title={<i className="bi bi-trash-fill"></i>}
          />
        </td>
      </tr>
      {open && (
        <Modal
          title="Warning!"
          onDismiss={toggleOpen}
          onDelete={handleDelete}
          loading={isLoadingDelete}
        >
          <p>Are you sure you want to delete this keyword?</p>
        </Modal>
      )}
    </>
  );
};
