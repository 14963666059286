import { createHeaders } from "../../utils/api";

const getTranslation = (data) => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/translate`, {
    method: "POST",
    headers: createHeaders(headers),
    body: JSON.stringify(data),
  });
};

const saveTranslations = (variantId, data) => (headers) => {
  return fetch(
    `${process.env.REACT_APP_BASE_API}/variant/${variantId}/ingredients`,
    {
      method: "POST",
      headers: createHeaders(headers),
      body: JSON.stringify(data),
    }
  );
};

export { getTranslation, saveTranslations };
