import TableControl from "../../../components/TableControl";
import Title from "../../../components/Title";
import useTitle from "../../../core/hooks/useTitle";
import { Routes } from "../../../core/routing";
import ActiveProjects from "../../shared/projects/ActiveProjects";
import PastProjects from "../../shared/projects/PastProjects";

const ProjectOverview = () => {
  useTitle("Projects");

  return (
    <>
      <Title title="Projects" />
      <TableControl name="project" link={Routes.Projects.Create} />
      <ActiveProjects />
      <PastProjects />
    </>
  );
};

export default ProjectOverview;
