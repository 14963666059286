import React from "react";
import PropTypes from "prop-types";

const SmallInput = React.forwardRef(
  (
    {
      type = "number",
      name,
      onChange,
      value,
      error,
      validated,
      disabled,
      extraStyle,
      ...rest
    },
    ref
  ) => {
    return (
      <>
        <input
          className={`form-control smallInput ${
            validated ? (error ? "is-invalid" : "is-valid") : ""
          }`}
          type={type}
          name={name}
          ref={ref}
          value={value}
          onChange={onChange}
          disabled={disabled}
          {...rest}
        />
        {error && <div className="invalid-feedback">{error}</div>}
      </>
    );
  }
);

SmallInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default SmallInput;
