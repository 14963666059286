import * as yup from "yup";
import { format } from "date-fns";
import CreatableSelect from "react-select/creatable";
import useForm from "../../../core/hooks/useForm";
import Button from "../Button";
import Input from "../Input";
import ProjectSelectCompany from "./ProjectSelectCompany";
import ProjectSelectUser from "./ProjectSelectUser";

const defaultData = {
  name: "",
  brand: "",
  companyId: "",
  userId: "",
  budget: "",
  job: "",
  description: "",
  startDate: format(new Date(), "yyyy-MM-dd"),
  languages: [],
};

const languageData = [
  { value: "dutch", label: "Dutch" },
  { value: "french", label: "French" },
  { value: "german", label: "German" },
];

let schema = yup.object().shape({
  name: yup.string().required(),
  job: yup.string().required(),
  brand: yup.string().required(),
  companyId: yup
    .string()
    .required()
    .test("not-default", "Please select a company", (value) => value !== "--"),
  userId: yup
    .string()
    .required()
    .test("not-default", "Please select a user", (value) => value !== "--"),
  budget: yup.string().required(),
  description: yup.string().required(),
  startDate: yup.string().required(),
  languages: yup.array().required(),
});

const ProjectForm = ({ initialData = {}, onSubmit, onDelete, disabled }) => {
  const initial = {
    ...defaultData,
    ...initialData,
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    isTouched,
    setValues,
  } = useForm(schema, initial);

  const handleData = (values) => {
    onSubmit(values);
  };

  const changeMultiple = (inputValue) => {
    setValues({
      ...values,
      languages: inputValue,
    });
  };

  return (
    <form onSubmit={handleSubmit(handleData)} noValidate>
      <div className="row">
        <div className="col-6 border-end border-2">
          <Input
            label="MM Jobnummer"
            name="job"
            type="number"
            value={values.job}
            onChange={handleChange}
            error={errors.job}
            validated={isTouched}
            disabled={disabled}
          />
          <Input
            label="Project name"
            name="name"
            value={values.name}
            onChange={handleChange}
            error={errors.name}
            validated={isTouched}
            disabled={disabled}
          />
          <Input
            label="Brand"
            name="brand"
            value={values.brand}
            onChange={handleChange}
            error={errors.brand}
            validated={isTouched}
            disabled={disabled}
          />
          <ProjectSelectCompany
            label="Company"
            name="companyId"
            value={values.companyId}
            onChange={handleChange}
            error={errors.companyId}
            validated={isTouched}
            disabled={disabled}
          />

          {/* TODO on companychange set user to null */}
          {values.companyId !== "" ? (
            <ProjectSelectUser
              label="Project-manager"
              name="userId"
              value={values.userId}
              onChange={handleChange}
              error={errors.userId}
              validated={isTouched}
              disabled={disabled}
              id={values.companyId}
            />
          ) : (
            <>
              <label>Project-manager</label>
              <select className="form-select">
                <option>--</option>
              </select>
            </>
          )}
        </div>

        <div className="col-6 border-end border-2">
          <Input
            label="Description"
            name="description"
            value={values.description}
            onChange={handleChange}
            error={errors.description}
            validated={isTouched}
            disabled={disabled}
          />

          <Input
            label="Budget"
            name="budget"
            type="number"
            value={values.budget}
            onChange={handleChange}
            error={errors.budget}
            validated={isTouched}
            disabled={disabled}
          />

          <Input
            label="Startdate"
            name="startDate"
            type="date"
            value={values.startDate}
            onChange={handleChange}
            error={errors.startDate}
            validated={isTouched}
            disabled={disabled}
          />

          <label>Languages</label>
          <CreatableSelect
            name="languages"
            isMulti
            options={languageData}
            onChange={changeMultiple}
            value={values.languages}
          />

          <div className="d-grid gap-2 d-flex justify-content-md-end align-self-end mt-4">
            <Button type="submit" title="Submit" loading={disabled} />
            {onDelete && (
              <Button
                type="button"
                title="Delete"
                color="danger"
                onClick={onDelete}
                disabled={disabled}
              />
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default ProjectForm;
