import { useCallback } from "react";
import useFetch from "../../../core/hooks/useFetch";
import { fetchUserByCompanyId } from "../../../core/modules/users/api";
import Spinner from "../../Spinner";
import Select from "../Select";

const ProjectSelectUser = ({
  label,
  name,
  onChange,
  value,
  error,
  validated,
  disabled,
  id,
}) => {
  const apiCall = useCallback(() => {
    return fetchUserByCompanyId(id);
  }, [id]);

  const { data, isLoading } = useFetch(apiCall);

  if (isLoading) {
    return <Spinner size="1rem" />;
  }

  return (
    <Select
      label={label}
      name={name}
      value={value}
      data={data}
      onChange={onChange}
      error={error}
      validated={validated}
      disabled={disabled}
    />
  );
};

export default ProjectSelectUser;
