import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import Title from "../../../../components/Title";
import useFetch from "../../../../core/hooks/useFetch";
import { fetchVariantById } from "../../../../core/modules/variants/api";
import Spinner from "../../../../components/Spinner";
import ErrorAlert from "../../../../components/errors/ErrorAlert";
import { IngredientsInputForm } from "../../../../components/forms/ingredients/IngredientsInputForm";
import { IngredientsTranslateForm } from "../../../../components/forms/ingredients/IngredientsTranslateForm";
import useAuthApi from "../../../../core/hooks/useAuthApi";
import {
  getTranslation,
  saveTranslations,
} from "../../../../core/modules/variants/translationApi";

export const IngredientsView = () => {
  const { variantId } = useParams();

  const apiCall = useCallback(() => {
    return fetchVariantById(variantId, "?section=ingredients");
  }, [variantId]);

  const withAuth = useAuthApi();
  const [isLoadingTranslation, setIsLoadingTranslation] = useState(false);
  const [errorTranslation, setErrorTranslation] = useState();
  const [translations, setTranslations] = useState();

  const { data: ingredients, error, isLoading, refresh } = useFetch(apiCall);

  const handleTranslate = (data) => {
    setIsLoadingTranslation(true);
    withAuth(
      getTranslation({
        ...data,
      })
    )
      .then((res) => {
        setTranslations(res);
        setIsLoadingTranslation(false);
      })
      .catch((err) => {
        setErrorTranslation(err);
        setIsLoadingTranslation(false);
      });
  };

  const handleSave = (data) => {
    setErrorTranslation(null);
    setIsLoadingTranslation(true);
    withAuth(saveTranslations(variantId, data))
      .then((res) => {
        refresh();
        setIsLoadingTranslation(false);
        console.log(res);
      })
      .catch((err) => {
        setErrorTranslation(err);
        setIsLoadingTranslation(false);
      });
  };

  if (isLoading) {
    return <Spinner fill />;
  }

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <>
      <Title title={`${ingredients.variantName} - Ingredients`} back />
      <IngredientsInputForm
        data={ingredients}
        onSubmit={handleTranslate}
        disabled={isLoadingTranslation}
        error={errorTranslation}
      />

      {/* if translation is triggered, show loading animation */}
      {isLoadingTranslation ? (
        <Spinner height={25} />
      ) : (
        <IngredientsTranslateForm
          languages={ingredients.languages}
          onSubmit={handleSave}
          initialData={
            translations
              ? translations
              : ingredients.ingredients
              ? ingredients.ingredients
              : null
          }
        />
      )}
    </>
  );
};
