import { useState } from "react";
import useAuthApi from "../../core/hooks/useAuthApi";
import { deleteVariant } from "../../core/modules/variants/api";
import CardTable from "../CardTable";
import Button from "../forms/Button";
import VariantRow from "./VartiantRow";
import Modal from "../Modal";
import { Routes } from "../../core/routing";

const HeaderButtons = ({ id, refresh }) => {
  const withAuth = useAuthApi();
  const [isLoading, setIsLoading] = useState();
  const [open, setOpen] = useState();

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleDelete = () => {
    setIsLoading(true);
    withAuth(deleteVariant(id)).then(() => {
      refresh();
    });
  };

  return (
    <>
      <div
        className="btn-group float-end"
        role="group"
        aria-label="Basic example"
      >
        {/* <button className="btn btn-sm btn-warning float-end">
        <i className="bi bi-pencil-square"></i>
      </button> */}
        <Button
          extraStyle="btn-sm float-end"
          color="danger"
          onClick={toggleOpen}
          title={<i className="bi bi-trash-fill"></i>}
          disabled={isLoading}
        />
      </div>
      {open && (
        <Modal
          title="Warning!"
          onDismiss={toggleOpen}
          onDelete={handleDelete}
          loading={isLoading}
        >
          <p>Are you sure you want to delete this variant?</p>
        </Modal>
      )}
    </>
  );
};

const VariantTable = ({ data, refresh }) => {
  return (
    <CardTable
      title={data.name}
      headerContent={<HeaderButtons id={data.id} refresh={refresh} />}
    >
      <tbody>
        {/* <VariantRow
          title="General Info"
          link={Routes.Variants.General}
          variantId={data.id}
        /> */}
        <VariantRow
          title="Ingredients"
          data={data.ingredients}
          link={Routes.Variants.Ingredients}
          variantId={data.id}
        />
        {/* <VariantRow
          title="Nutritional data"
          link={Routes.Variants.Nutritional}
          variantId={data.id}
        /> */}
        {/* <VariantRow
          title="Bereiden en bewaren"
          link={Routes.Variants.Serving}
          variantId={data.id}
        /> */}
      </tbody>
    </CardTable>
  );
};

export default VariantTable;
