import { useState } from "react";
import * as yup from "yup";
import { useAuth } from "../../../App";
import useAuthApi from "../../../core/hooks/useAuthApi";
import useForm from "../../../core/hooks/useForm";
import { updatePassword } from "../../../core/modules/users/api";
import ErrorAlert from "../../errors/ErrorAlert";
import Button from "../Button";
import FormTitle from "../FormTitle";
import Input from "../Input";

const defaultData = {
  newPass: "",
  newPassVerify: "",
};

let schema = yup.object().shape({
  newPass: yup.string().required(),
  newPassVerify: yup
    .string()
    .oneOf([yup.ref("newPass"), null], "Passwords don't match!")
    .required("This is a required field"),
});

const PasswordForm = ({ initialData = {} }) => {
  const [validationError, setValidationError] = useState();
  const [isLoading, setIsLoading] = useState();

  const initial = {
    ...defaultData,
    ...initialData,
  };

  const { values, errors, handleSubmit, handleChange, isTouched } = useForm(
    schema,
    initial
  );

  const withAuth = useAuthApi();
  const { user, logout } = useAuth();

  const onSubmit = (data) => {
    setIsLoading(true);
    withAuth(updatePassword(data))
      .then(() => {
        logout();
      })
      .catch((err) => {
        setValidationError(err);
        setIsLoading(false);
      });
  };

  const handleData = (values) => {
    onSubmit({
      id: user._id,
      password: values.newPass,
    });
  };

  return (
    <form onSubmit={handleSubmit(handleData)} noValidate>
      {validationError && <ErrorAlert error={validationError} />}
      <FormTitle title="Change Password" />
      <Input
        label="New password"
        name="newPass"
        type="password"
        value={values.newPass}
        onChange={handleChange}
        disabled={isLoading}
      />
      <Input
        label="Verify new password"
        name="newPassVerify"
        type="password"
        value={values.newPassVerify}
        onChange={handleChange}
        error={errors.newPassVerify}
        validated={isTouched}
        disabled={isLoading}
      />
      <div className="d-grid gap-2 d-flex justify-content-md-end align-self-end mt-4">
        <Button
          type="submit"
          title="Change password"
          loading={isLoading}
          color="danger"
        />
      </div>
    </form>
  );
};

export default PasswordForm;
