import useFetch from "../../../core/hooks/useFetch";
import { fetchCompanies } from "../../../core/modules/companies/api";
import Spinner from "../../Spinner";
import Select from "../Select";

const UserSelectCompany = ({
  label,
  name,
  onChange,
  value,
  error,
  validated,
  disabled,
}) => {
  const { data, isLoading } = useFetch(fetchCompanies);

  if (isLoading) {
    return <Spinner size="1rem" height={25} />;
  }

  return (
    <Select
      label={label}
      name={name}
      value={value}
      data={data}
      onChange={onChange}
      error={error}
      validated={validated}
      disabled={disabled}
    />
  );
};

export default UserSelectCompany;
