import { useEffect, useCallback, useReducer } from "react";
import useAuthApi from "./useAuthApi";

const initialState = {
  data: null,
  error: null,
  page: 1,
  pageSize: 10,
  totalPageCount: 1,
};

const Actions = {
  Prev: "prevPage",
  Next: "nextPage",
  Data: "setData",
  Error: "setError",
  Clear: "clear",
  PageSize: "setPageSize",
};

const reducer = (state, action) => {
  switch (action.type) {
    case Actions.Prev:
      console.log(state.page, state.totalPageCount);
      return {
        ...state,
        page: Math.max(state.page - 1, 1),
      };
    case Actions.Next:
      console.log(state.page, state.totalPageCount);
      return {
        ...state,
        page: Math.min(state.page + 1, state.totalPageCount),
      };
    case Actions.Clear:
      return {
        ...state,
        data: null,
        error: null,
      };
    case Actions.Data:
      // const totalCount = action.payload.length;
      return {
        ...state,
        // totalPageCount: Math.ceil(totalCount / state.pageSize),
        data: action.payload,
      };
    case Actions.Error:
      return {
        ...state,
        error: action.payload,
      };
    case Actions.PageSize:
      return {
        ...state,
        pageSize: action.payload,
      };
    default:
      return state;
  }
};

const useFetch = (apiCall) => {
  const withAuth = useAuthApi();

  const [state, dispatch] = useReducer(reducer, initialState);

  const { data, error } = state;

  // TODO show data of current page
  const paginatedData = data;

  const nextPage = useCallback(() => dispatch({ type: Actions.Next }), []);
  const prevPage = useCallback(() => dispatch({ type: Actions.Prev }), []);
  const setPageSize = useCallback(
    (length) => dispatch({ type: Actions.PageSize, payload: length }),
    []
  );
  const setData = useCallback(
    (data) => dispatch({ type: Actions.Data, payload: data }),
    []
  );
  const setError = useCallback(
    (error) => dispatch({ type: Actions.Error, payload: error }),
    []
  );

  const fetchData = useCallback(() => {
    let isCurrent = true;

    withAuth(apiCall())
      .then((data) => isCurrent && setData(data))
      .catch((error) => isCurrent && setError(error));

    return () => {
      isCurrent = false;
    };
  }, [setData, setError, apiCall, withAuth]);

  const refresh = () => {
    setData(null);
    fetchData();
  };

  useEffect(() => {
    dispatch({ type: Actions.Clear });
    if (apiCall) {
      const cleanUp = fetchData();
      return cleanUp;
    }
  }, [apiCall, fetchData]);

  const isLoading = !data && !error;

  return {
    data,
    setData,
    error,
    nextPage,
    prevPage,
    refresh,
    isLoading,
    paginatedData,
    setPageSize,
  };
};

export default useFetch;
