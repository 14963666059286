const Routes = Object.freeze({
  Login: "/login",
  Profile: "/profile",
  Dashboard: "/dashboard",
  Projects: {
    Index: "/projects",
    Detail: "/projects/:id",
    Create: "/projects/add",
    Edit: "/projects/:id/edit",
  },
  Companies: {
    Index: "/companies",
    Detail: "/companies/:id",
    Create: "/companies/add",
  },
  Users: {
    Index: "/users",
    Create: "/users/add",
    Edit: "/users/:id",
  },
  Variants: {
    General: "/projects/variant/:variantId/general_info",
    Ingredients: "/projects/variant/:variantId/ingredients",
    Nutritional: "/projects/variant/:variantId/nutritional_data",
    Serving: "/projects/variant/:variantId/Serving",
  },
  Translations: {
    Index: "/translations",
    Create: "/translations/create",
    Edit: "/translations/:id/edit",
  },
});

// replaces : values with values from object
// e.g. route('/projects/:id', { id : 9 }) -> /projects/9
export const route = (path, options = {}) => {
  Object.keys(options).forEach((key) => {
    path = path.replace(`:${key}`, options[key]);
  });
  return path;
};

export { Routes };
