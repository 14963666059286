import { TranslationRow } from "./TranslationRow";

export const TranslationsTable = ({
  translations,
  refresh,
  additive = false,
}) => {
  if (!translations.length) {
    return (
      <tbody>
        <tr>
          <td colSpan="4" className="text-start ps-3">
            <div className="table-filler">
              <h2>... Nothing here</h2>
            </div>
          </td>
        </tr>
      </tbody>
    );
  }
  return (
    <>
      <thead>
        <tr>
          <th scope="col" className="text-start ps-3">
            Dutch
          </th>
          <th scope="col">French</th>
          <th scope="col">German</th>
          {additive && <th scope="col">Number</th>}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {translations.map((translation, index) => (
          <TranslationRow
            key={index}
            translation={translation}
            refresh={refresh}
            additive={additive}
          />
        ))}
      </tbody>
    </>
  );
};
