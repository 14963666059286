import PropTypes from "prop-types";

const FloatingInput = ({ type, label, name, value, onChange }) => {
  return (
    <div className="form-floating text-start">
      <input
        type={type}
        className="form-control"
        id={name}
        value={value}
        onChange={onChange}
      />

      <label htmlFor={name}>{label}</label>
    </div>
  );
};

FloatingInput.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FloatingInput;
