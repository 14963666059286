import * as yup from "yup";
import useForm from "../../../core/hooks/useForm";
import SmallInput from "../SmallInput";
import Button from "../Button";
import { NutritionRow } from "./NutritionRow";

const defaultData = {
  portion: "100",
  package: 1,
  energy: 0,
  energyPortion: 0,
  energyProcent: 0,
  fat: 0,
  fatPortion: 0,
  fatProcent: 0,
  saturated: 0,
  saturatedPortion: 0,
  saturatedProcent: 0,
  carbon: 0,
  carbonPortion: 0,
  carbonProcent: 0,
  sugar: 0,
  sugarPortion: 0,
  sugarProcent: 0,
  lipides: 0,
  lipidesPortion: 0,
  lipidesProcent: 0,
  salt: 0,
  saltPortion: 0,
  saltProcent: 0,
  fibre: 0,
  fibrePortion: 0,
  fibreProcent: 0,
};

let schema = yup.object().shape({
  portion: yup.number().required(),
  package: yup.number().required(),
  energy: yup.number().required(),
  energyPortion: yup.number().required(),
  energyProcent: yup.number().required(),
  fat: yup.number().required(),
  fatPortion: yup.number().required(),
  fatProcent: yup.number().required(),
  saturated: yup.number().required(),
  saturatedPortion: yup.number().required(),
  saturatedProcent: yup.number().required(),
  carbon: yup.number().required(),
  carbonPortion: yup.number().required(),
  carbonProcent: yup.number().required(),
  sugar: yup.number().required(),
  sugarPortion: yup.number().required(),
  sugarProcent: yup.number().required(),
  lipides: yup.number().required(),
  lipidesPortion: yup.number().required(),
  lipidesProcent: yup.number().required(),
  salt: yup.number().required(),
  saltPortion: yup.number().required(),
  saltProcent: yup.number().required(),
  fibre: yup.number().required(),
  fibrePortion: yup.number().required(),
  fibreProcent: yup.number().required(),
});

export const NutritionForm = ({ initialData = {}, onSubmit, loading }) => {
  const initial = {
    ...defaultData,
    ...initialData,
  };

  const { values, errors, handleChange, handleSubmit, isTouched } = useForm(
    schema,
    initial
  );

  const handleData = (values) => {
    onSubmit(values);
  };

  return (
    <div className="row">
      <div className="col-6 border-end border-2">
        <form onSubmit={handleSubmit(handleData)} noValidate>
          <div className="nutritionTop">
            <p>
              1 Portie =
              <SmallInput
                name="portion"
                value={values.portion}
                onChange={handleChange}
                error={errors.portion}
                validated={isTouched}
                disabled={loading}
              />
              g
            </p>
            <p>
              De verpakking bevat
              <SmallInput
                name="package"
                value={values.package}
                onChange={handleChange}
                error={errors.package}
                validated={isTouched}
                disabled={loading}
              />
              porties
            </p>
          </div>

          <table className="table table-bordered mt-2">
            <thead className="table-light">
              <tr>
                <th scope="col">Nutritional Values</th>
                <th scope="col">/100 g</th>
                {values.portion !== "100" && (
                  <th scope="col">{`/${values.portion} g`}</th>
                )}
                <th scope="col">Procent RI</th>
              </tr>
            </thead>
            <tbody>
              <NutritionRow
                title="Energy"
                name="energy"
                hundred={values.energy}
                portion={values.energyPortion}
                procent={values.energyProcent}
                onChange={handleChange}
                portionRow={values.portion !== "100"}
              />
              <NutritionRow
                title="Fat"
                name="fat"
                hundred={values.fat}
                portion={values.fatPortion}
                procent={values.fatProcent}
                onChange={handleChange}
                portionRow={values.portion !== "100"}
              />
              <NutritionRow
                title="of which saturated"
                name="saturated"
                hundred={values.saturated}
                portion={values.saturatedPortion}
                procent={values.saturatedProcent}
                onChange={handleChange}
                portionRow={values.portion !== "100"}
              />
              <NutritionRow
                title="Carbon"
                name="carbon"
                hundred={values.carbon}
                portion={values.carbonPortion}
                procent={values.carbonProcent}
                onChange={handleChange}
                portionRow={values.portion !== "100"}
              />
              <NutritionRow
                title="of which sugar"
                name="sugar"
                hundred={values.sugar}
                portion={values.sugarPortion}
                procent={values.sugarProcent}
                onChange={handleChange}
                portionRow={values.portion !== "100"}
              />
              <NutritionRow
                title="Lipides"
                name="lipides"
                hundred={values.lipides}
                portion={values.lipidesPortion}
                procent={values.lipidesProcent}
                onChange={handleChange}
                portionRow={values.portion !== "100"}
              />
              <NutritionRow
                title="Fibres"
                name="fibre"
                hundred={values.fibre}
                portion={values.fibrePortion}
                procent={values.fibreProcent}
                onChange={handleChange}
                portionRow={values.portion !== "100"}
              />
              <NutritionRow
                title="Salt"
                name="salt"
                hundred={values.salt}
                portion={values.saltPortion}
                procent={values.saltProcent}
                onChange={handleChange}
                portionRow={values.portion !== "100"}
              />
            </tbody>
          </table>
          <Button type="submit" title="Submit" loading={loading} />
        </form>
      </div>
      <div className="col-6">
        <h3>Resultaat:</h3>
      </div>
    </div>
  );
};
