import Alert from "../Alert";

const ErrorAlert = ({ error, color = "danger" }) => {
  if (!error) {
    return null;
  }

  return <Alert color={color}>{error.message || "Something went wrong"}</Alert>;
};

export default ErrorAlert;
