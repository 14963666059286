import { useState } from "react";
import { Link } from "react-router-dom";

const TableControl = ({ name = "", link, table, query, setQuery }) => {
  const [search, setSearch] = useState(query);

  const handlechange = (e) => {
    setSearch(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setQuery(search);
  };

  return (
    <div className={`d-flex ${!table && "mb-3"}`}>
      <Link to={link} className="btn btn-warning">
        <i className="bi bi-plus-lg me-2"></i>
        {`Add ${name}`}
      </Link>
      {setQuery && (
        <form className="input-group  w-25 ms-auto" onSubmit={handleSubmit}>
          <button className="input-group-text" id="basic-addon1" type="submit">
            <i className="bi bi-search"></i>
          </button>
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            value={search}
            onChange={handlechange}
          />
        </form>
      )}
    </div>
  );
};

export default TableControl;
