import PropTypes from "prop-types";

const Card = ({ children, title }) => {
  return (
    <div className="card mb-4">
      <div className="card-header">{title}</div>
      <div className="card-body">{children}</div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Card;
