import PropTypes from "prop-types";

const CardTable = ({ children, title, headerContent }) => {
  return (
    <div className="card mb-4">
      <div className="card-header">
        {title}
        {headerContent && headerContent}
      </div>
      <table className="table m-0 align-middle">{children}</table>
    </div>
  );
};

CardTable.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default CardTable;
