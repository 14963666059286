import React from "react";
import PropTypes from "prop-types";

const Input = React.forwardRef(
  (
    {
      type = "text",
      label,
      name,
      onChange,
      value,
      error,
      validated,
      disabled,
      ...rest
    },
    ref
  ) => {
    return (
      <div className="form-group mb-2">
        {label && <label htmlFor={name}>{label}</label>}
        <input
          className={`form-control ${
            validated ? (error ? "is-invalid" : "is-valid") : ""
          }`}
          type={type}
          onKeyDown={
            type === "number"
              ? (evt) => evt.key === "e" && evt.preventDefault()
              : null
          }
          name={name}
          ref={ref}
          value={value}
          onChange={onChange}
          disabled={disabled}
          {...rest}
        />
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    );
  }
);

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default Input;
