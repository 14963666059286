import CompanyTable from "../../../components/companies/CompanyTable";
import ErrorAlert from "../../../components/errors/ErrorAlert";
import Spinner from "../../../components/Spinner";
import Title from "../../../components/Title";
import useFetch from "../../../core/hooks/useFetch";
import useTitle from "../../../core/hooks/useTitle";
import { fetchCompanies } from "../../../core/modules/companies/api";

const CompanyOverview = () => {
  const { data: companies, error, isLoading } = useFetch(fetchCompanies);

  useTitle("Companies");

  if (isLoading) {
    return <Spinner fill size="3rem" />;
  }

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <>
      <Title title="Companies" />

      <CompanyTable companies={companies} />
    </>
  );
};

export default CompanyOverview;
