const Select = ({
  data = [],
  label,
  name,
  onChange,
  value,
  error,
  validated,
  disabled,
}) => {
  return (
    <div className="form-group mb-2">
      {label && <label htmlFor={name}>{label}</label>}
      <select
        value={value || ""}
        name={name}
        disabled={disabled}
        onChange={onChange}
        className={`form-select ${
          validated ? (error ? "is-invalid" : "is-valid") : ""
        }`}
      >
        <option>--</option>
        {data.map((company, index) => (
          <option key={index} value={company.id}>
            {company.name}
          </option>
        ))}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default Select;
