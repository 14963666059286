import { useCallback, useState } from "react";
import { useAuth } from "../../App";
import ErrorAlert from "../../components/errors/ErrorAlert";
import ConfirmationForm from "../../components/forms/Companies/ConfirmationForm";
import Spinner from "../../components/Spinner";
import useAuthApi from "../../core/hooks/useAuthApi";
import useFetch from "../../core/hooks/useFetch";
import useTitle from "../../core/hooks/useTitle";
import { fetchCompany, updateCompany } from "../../core/modules/companies/api";

const Confirmation = () => {
  const { user, token, updateAuth } = useAuth();
  const withAuth = useAuthApi();

  const [validationError, setValidationError] = useState();

  useTitle("Confirmation");

  const apiCall = useCallback(() => {
    return fetchCompany(user.company.id);
  }, [user.company.id]);

  const { data: company, error, isLoading } = useFetch(apiCall);

  const handleSubmit = (data) => {
    withAuth(updateCompany(data))
      .then(() => {
        updateAuth({
          user: {
            ...user,
            company: {
              ...user.company,
              reviewed: true,
            },
          },
          token: token,
        });
      })
      .catch((err) => {
        setValidationError(err);
      });
  };

  if (isLoading) {
    return <Spinner fill size="5rem" />;
  }

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <div className="container p-5">
      <div className="row">
        {validationError && <ErrorAlert error={validationError} />}
        <div className="col">
          <h1>{`Welcome ${user.name}!`}</h1>
          <p className="lead">
            Because this is your company's first login we need some extra
            information
          </p>
        </div>
      </div>

      <div className="row mt-4">
        <ConfirmationForm initialData={company} onSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default Confirmation;
