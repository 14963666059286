import { createHeaders } from "../../utils/api";

const createTranslation = (data) => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/translations`, {
    method: "POST",
    headers: createHeaders(headers),
    body: JSON.stringify(data),
  });
};

const fetchTranslations = (query) => (headers) => {
  return fetch(
    `${process.env.REACT_APP_BASE_API}/translations${
      query ? "?search=" + query : ""
    }`,
    {
      headers: createHeaders(headers),
    }
  );
};

const fetchTranslation = (id) => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/translations/${id}`, {
    headers: createHeaders(headers),
  });
};

const updateTranslations = (data) => (headers) => {
  const { id } = data;
  return fetch(`${process.env.REACT_APP_BASE_API}/translations/${id}`, {
    method: "PATCH",
    headers: createHeaders(headers),
    body: JSON.stringify(data),
  });
};

const deleteTranslation = (id) => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/translations/${id}`, {
    method: "DELETE",
    headers: createHeaders(headers),
  });
};

export {
  fetchTranslations,
  fetchTranslation,
  createTranslation,
  updateTranslations,
  deleteTranslation,
};
