import { useState } from "react";
import { useHistory } from "react-router";
import useAuthApi from "../../core/hooks/useAuthApi";
import { updateProject } from "../../core/modules/projects/api";
import { Routes, route } from "../../core/routing";
import ErrorAlert from "../errors/ErrorAlert";
import Button from "../forms/Button";

export const ProjectActions = ({ id, data, refresh, setData, onClickAdd }) => {
  const history = useHistory();
  const withAuth = useAuthApi();

  const [error, setError] = useState();

  const handelVisible = () => {
    setData({
      ...data,
      visible: !data.visible,
    });
    withAuth(
      updateProject({
        _id: id,
        visible: !data.visible,
      })
    ).catch((err) => {
      setError(err);
    });
  };

  const handelActive = () => {
    setData({
      ...data,
      active: !data.active,
    });
    withAuth(
      updateProject({
        _id: id,
        active: !data.active,
      })
    ).catch((err) => {
      setError(err);
    });
  };

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <>
      <div className="text-end">
        <div className="d-md-block">
          <Button
            color="primary"
            title="Add variant"
            extraStyle="me-2"
            onClick={onClickAdd}
          />
          <Button
            color="warning"
            title="Edit project"
            extraStyle="me-2"
            onClick={() => {
              history.push(route(Routes.Projects.Edit, { id: id }));
            }}
          />
          {/* <Button
            color={data.visible ? "success" : "danger"}
            title={data.visible ? "Visible" : "Invisible"}
            extraStyle="me-2"
            onClick={handelVisible}
          /> */}
          <Button
            color={data.active ? "success" : "danger"}
            title={data.active ? "Ongoing" : "Finished"}
            onClick={handelActive}
          />
        </div>
      </div>
      <hr />
    </>
  );
};
