import { GeneralInfoForm } from "../../../../components/forms/GeneralInfo/GeneralInfoForm";
import Title from "../../../../components/Title";

export const GeneralDataView = () => {
  return (
    <>
      <Title title={"General info - TODO Dynamic"} back />
      <GeneralInfoForm />
    </>
  );
};
