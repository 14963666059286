export const Checkbox = ({
  label,
  name,
  onChange,
  value = "",
  error,
  validated,
  disabled,
  checked,
}) => {
  return (
    <div className="form-check">
      <input
        className={`form-check-input ${
          validated ? (error ? "is-invalid" : "is-valid") : ""
        }`}
        type="checkbox"
        value={value}
        id={name}
        disabled={disabled}
        onChange={onChange}
        defaultChecked={checked}
      />
      {label && (
        <label htmlFor={name} className="form-check-label">
          {label}
        </label>
      )}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};
