import * as yup from "yup";
import useForm from "../../../core/hooks/useForm";
import Button from "../Button";
import Input from "../Input";
import SelectTranslate from "../SelectTranslate";

const defaultData = {
  dutch: "",
  french: "",
  german: "",
  type: "",
  number: "",
};

let schema = yup.object().shape({
  dutch: yup.string().required(),
  french: yup.string().required(),
  german: yup.string().required(),
  type: yup
    .string()
    .oneOf(["Ingredient", "Allergen", "Additive", "Extra"])
    .required(),
});

export const TranslationForm = ({ initialData = {}, onSubmit, loading }) => {
  const initial = {
    ...defaultData,
    ...initialData,
  };
  const { values, errors, handleChange, handleSubmit, isTouched } = useForm(
    schema,
    initial
  );

  const handleData = (values) => {
    onSubmit(values);
  };

  return (
    <form onSubmit={handleSubmit(handleData)} noValidate>
      <SelectTranslate
        data={["Ingredient", "Allergen", "Additive", "Extra"]}
        label="Type"
        name="type"
        value={values.type}
        onChange={handleChange}
        error={errors.type}
        validated={isTouched}
        disabled={loading}
      />
      {values.type === "Additive" && (
        <Input
          type="number"
          label="Number"
          name="number"
          value={values.number}
          onChange={handleChange}
          error={errors.number}
          validated={isTouched}
          disabled={loading}
        />
      )}
      <Input
        label="Dutch"
        name="dutch"
        value={values.dutch}
        onChange={handleChange}
        error={errors.dutch}
        validated={isTouched}
        disabled={loading}
      />
      <Input
        label="French"
        name="french"
        value={values.french}
        onChange={handleChange}
        error={errors.french}
        validated={isTouched}
        disabled={loading}
      />
      <Input
        label="German"
        name="german"
        value={values.german}
        onChange={handleChange}
        error={errors.german}
        validated={isTouched}
        disabled={loading}
      />
      <div className="d-flex justify-content-md-end align-self-end mt-4">
        <Button
          type="submit"
          title="Submit"
          disabled={loading}
          loading={loading}
        />
      </div>
    </form>
  );
};
