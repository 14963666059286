import { useCallback } from "react";
import { useAuth } from "../../../App";
import Card from "../../../components/Card";
import ErrorAlert from "../../../components/errors/ErrorAlert";
import ProjectTable from "../../../components/projects/ProjectTable";
import Spinner from "../../../components/Spinner";
import useFetch from "../../../core/hooks/useFetch";
import { fetchProjects } from "../../../core/modules/projects/api";

const PastProjects = () => {
  const { user } = useAuth();

  const apiCall = useCallback(() => {
    return fetchProjects("?active=false");
  }, []);

  const { data: projects, error, isLoading } = useFetch(apiCall);

  if (isLoading) {
    return (
      <Card title="Past Projects">
        <Spinner />
      </Card>
    );
  }

  if (error) {
    return <ErrorAlert error={error} />;
  }

  if (user.role === "admin") {
    return <ProjectTable projects={projects} title="Past Projects" />;
  }

  return <ProjectTable projects={projects} title="Past Projects" user />;
};

export default PastProjects;
