import { useState } from "react";
import Button from "../../components/forms/Button";
import * as yup from "yup";
import { getValidationErrors } from "../../core/utils/Validation";
import FloatingInput from "../../components/forms/FloatingInput";
import Alert from "../../components/Alert";

let schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const LoginForm = ({ onSubmit, validationError }) => {
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const handleOnChange = (e) => {
    setData({
      ...data,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    schema
      .validate(data, { abortEarly: false })
      .then((valid) => {
        onSubmit(data);
        setErrors({});
      })
      .catch((err) => {
        setErrors(getValidationErrors(err));
      });
  };

  return (
    <>
      {errors.email || errors.password || validationError ? (
        <Alert>
          <ul className="text-start m-0">
            {errors.email && <li>{errors.email}</li>}
            {errors.password && <li>{errors.password}</li>}
            {validationError && <li>Wrong combination</li>}
          </ul>
        </Alert>
      ) : null}
      <div className="card">
        <form className="card-body" onSubmit={handleSubmit} noValidate>
          <FloatingInput
            label="E-mail"
            name="email"
            type="email"
            value={data.email}
            onChange={handleOnChange}
          />

          <FloatingInput
            label="Password"
            name="password"
            type="password"
            value={data.password}
            onChange={handleOnChange}
          />

          <Button type="submit" title="Login" extraStyle="w-50" />
        </form>
      </div>
    </>
  );
};

export default LoginForm;
