import { createHeaders } from "../../utils/api";

const fetchUsers = () => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/users`, {
    headers: createHeaders(headers),
  });
};

const fetchUser = (id) => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/users/${id}`, {
    headers: createHeaders(headers),
  });
};

const fetchUserByCompanyId = (companyId) => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/${companyId}/users/`, {
    headers: createHeaders(headers),
  });
};

const createUser = (data) => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/register`, {
    method: "POST",
    headers: createHeaders(headers),
    body: JSON.stringify(data),
  });
};

const updateUser = (data) => (headers) => {
  const { _id } = data;
  return fetch(`${process.env.REACT_APP_BASE_API}/users/${_id}`, {
    method: "PATCH",
    headers: createHeaders(headers),
    body: JSON.stringify(data),
  });
};

const updatePassword = (data) => (headers) => {
  const { id } = data;
  return fetch(`${process.env.REACT_APP_BASE_API}/password/${id}`, {
    method: "PATCH",
    headers: createHeaders(headers),
    body: JSON.stringify(data),
  });
};

const deleteUser = (id) => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/users/${id}`, {
    method: "DELETE",
    headers: createHeaders(headers),
  });
};

export {
  fetchUsers,
  createUser,
  fetchUser,
  updateUser,
  deleteUser,
  updatePassword,
  fetchUserByCompanyId,
};
