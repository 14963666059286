import * as yup from "yup";
import useForm from "../../../core/hooks/useForm";
import Button from "../Button";
import FormTitle from "../FormTitle";
import Input from "../Input";

const defaultData = {
  name: "",
  btw: "",
  street: "",
  number: "",
  extra: "",
  city: "",
  zip: "",
  country: "",
  email: "",
};

let schema = yup.object().shape({
  name: yup.string().required("Company name is required"),
  btw: yup.string(),
  street: yup.string(),
  number: yup.string(),
  extra: yup.string(),
  city: yup.string(),
  zip: yup.string(),
  country: yup.string(),
  email: yup.string().email(),
});

const CompanyForm = ({
  initialData = {},
  onSubmit,
  onEdit,
  onDelete,
  disabled,
  loading,
}) => {
  const initial = {
    ...defaultData,
    ...initialData,
  };
  const { values, errors, handleChange, handleSubmit, isTouched } = useForm(
    schema,
    initial
  );

  const handleData = (values) => {
    onSubmit(values);
  };

  return (
    <form onSubmit={handleSubmit(handleData)} noValidate>
      <div className="row">
        <div className="col border-end border-2">
          <FormTitle title="Company Details" />

          <Input
            label="Company name*"
            name="name"
            value={values.name}
            onChange={handleChange}
            error={errors.name}
            validated={isTouched}
            disabled={disabled || loading}
          />

          <div className="row">
            <div className="col-8">
              <Input
                label="Street"
                name="street"
                value={values.street}
                onChange={handleChange}
                error={errors.street}
                validated={isTouched}
                disabled={disabled || loading}
              />
            </div>

            <div className="col-4">
              <Input
                label="Number"
                name="number"
                value={values.number}
                onChange={handleChange}
                error={errors.number}
                validated={isTouched}
                disabled={disabled || loading}
              />
            </div>
          </div>

          <Input
            label="Extra line"
            name="extra"
            value={values.extra}
            onChange={handleChange}
            error={errors.extra}
            validated={isTouched}
            disabled={disabled || loading}
          />

          <div className="row">
            <div className="col-8">
              <Input
                label="City"
                name="city"
                value={values.city}
                onChange={handleChange}
                error={errors.city}
                validated={isTouched}
                disabled={disabled || loading}
              />
            </div>

            <div className="col-4">
              <Input
                label="Zip"
                name="zip"
                value={values.zip}
                onChange={handleChange}
                error={errors.zip}
                validated={isTouched}
                disabled={disabled || loading}
              />
            </div>
          </div>

          <Input
            label="Country"
            name="country"
            value={values.country}
            onChange={handleChange}
            error={errors.country}
            validated={isTouched}
            disabled={disabled || loading}
          />
        </div>

        <div className="col">
          <FormTitle title="Billing Details" />

          <Input
            label="Btw number"
            name="btw"
            value={values.btw}
            onChange={handleChange}
            error={errors.btw}
            validated={isTouched}
            disabled={disabled || loading}
          />

          <Input
            label="Billing e-mail"
            name="email"
            value={values.email}
            onChange={handleChange}
            error={errors.email}
            validated={isTouched}
            disabled={disabled || loading}
          />

          <div className="d-grid gap-2 d-flex justify-content-md-end align-self-end mt-4">
            <Button
              type="submit"
              title="Submit"
              disabled={disabled}
              loading={loading}
            />
            {onEdit && (
              <Button
                type="button"
                title="Edit"
                color="warning"
                onClick={onEdit}
                disabled={loading}
              />
            )}
            {onDelete && (
              <Button
                type="button"
                title="Delete"
                color="danger"
                onClick={onDelete}
                disabled={loading}
              />
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default CompanyForm;
