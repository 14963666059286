const Textarea = ({
  label,
  name,
  rows,
  onChange,
  value,
  error,
  validated,
  disabled,
}) => {
  return (
    <div className="form-group mb-2">
      {label && <label htmlFor={name}>{label}</label>}
      <textarea
        className={`form-control ${
          validated ? (error ? "is-invalid" : "is-valid") : ""
        }`}
        name={name}
        rows={rows}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default Textarea;
