import { useEffect } from "react";
import Button from "./forms/Button";

const Modal = ({ children, title, onDismiss, onDelete, loading }) => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        id="staticBackdrop"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {title}
              </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={onDismiss}
                disabled={loading}
              />
            </div>

            <div className="modal-body">{children}</div>

            <div className="modal-footer">
              <Button
                type="submit"
                title="Delete"
                color={"danger"}
                loading={loading}
                onClick={onDelete}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};

export default Modal;
