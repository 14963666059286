import { Link } from "react-router-dom";
import { Routes } from "../../core/routing";
import SidebarLink from "./SidebarLink";
import ProfileBar from "./ProfileBar";
import logo from "../../assets/images/mm-logo.png";

const Sidebar = () => {
  return (
    <div className="d-flex flex-column p-3 bg-light sidebar">
      {/* Sidebar logo */}
      <Link to={Routes.Dashboard} className="mx-auto">
        <img src={logo} alt="" height="56" />
      </Link>

      <hr />

      {/* Sidebar navigation */}
      <ul className="nav nav-pills flex-column mb-auto">
        <SidebarLink
          name="Dashboard"
          icon="house-door"
          link={Routes.Dashboard}
        />
        <SidebarLink
          name="Projects"
          icon="speedometer2"
          link={Routes.Projects.Index}
        />
        <SidebarLink
          name="Companies"
          icon="building"
          link={Routes.Companies.Index}
        />
        <SidebarLink
          name="Users"
          icon="person-circle"
          link={Routes.Users.Index}
        />

        <SidebarLink
          name="Translations"
          icon="translate"
          link={Routes.Translations.Index}
        />
      </ul>

      <hr className="mb-2" />

      {/* Profile icon / menu */}
      <ProfileBar />
    </div>
  );
};

export default Sidebar;
