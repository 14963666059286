import { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router";
import ErrorAlert from "../../../components/errors/ErrorAlert";
import Modal from "../../../components/Modal";
import Spinner from "../../../components/Spinner";
import Title from "../../../components/Title";
import useAuthApi from "../../../core/hooks/useAuthApi";
import useFetch from "../../../core/hooks/useFetch";
import useTitle from "../../../core/hooks/useTitle";
import {
  deleteUser,
  fetchUser,
  updateUser,
} from "../../../core/modules/users/api";
import { Routes } from "../../../core/routing";
import UserEditFormAdmin from "../../../components/forms/Users/UserEditFormAdmin";

const UserDetail = () => {
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [isLoadingPost, setIsLoadingPost] = useState();
  const [isLoadingDelete, setIsLoadingDelete] = useState();
  const [validationError, setValidationError] = useState();

  const { id } = useParams();

  const withAuth = useAuthApi();

  const apiCall = useCallback(() => {
    return fetchUser(id);
  }, [id]);

  const { data: user, error, isLoading } = useFetch(apiCall);

  useTitle(user ? user.firstName : "Edit User");

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleSubmit = (data) => {
    setIsLoadingPost(true);
    withAuth(updateUser(data))
      .then(() => {
        history.push(Routes.Users.Index);
      })
      .catch((err) => {
        setValidationError(err);
        setIsLoadingPost(false);
      });
  };

  const handleDelete = () => {
    setIsLoadingDelete(true);
    withAuth(deleteUser(id))
      .then(() => {
        history.push(Routes.Users.Index);
      })
      .catch((err) => {
        setValidationError(err);
        setIsLoadingDelete(false);
      });
  };

  if (isLoading) {
    return <Spinner fill size="5rem" />;
  }

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <>
      <Title title={`Edit ${user.name}`} back />
      {validationError && <ErrorAlert error={validationError} />}
      <UserEditFormAdmin
        initialData={user}
        onDelete={toggleOpen}
        onSubmit={handleSubmit}
        disabled={isLoadingPost}
      />

      {open && (
        <Modal
          title="Warning!"
          onDismiss={toggleOpen}
          onDelete={handleDelete}
          loading={isLoadingDelete}
        >
          <p>Are you sure you want to delete this User?</p>
        </Modal>
      )}
    </>
  );
};

export default UserDetail;
