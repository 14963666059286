import UserRow from "./UserRow";
import CardTable from "../CardTable";
import TableControl from "../TableControl";
import { Routes } from "../../core/routing";

const UserTable = ({ users }) => {
  return (
    <CardTable
      headerContent={
        <TableControl name="user" link={Routes.Users.Create} table />
      }
    >
      <thead>
        <tr>
          <th scope="col" className="text-start ps-3">
            Name
          </th>
          <th scope="col">Company</th>
          <th scope="col">E-mail</th>
          <th scope="col">Role</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => (
          <UserRow user={user} key={index} />
        ))}
      </tbody>
    </CardTable>
  );
};

export default UserTable;
