import { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router";
import ErrorAlert from "../../../components/errors/ErrorAlert";
import ProjectForm from "../../../components/forms/Projects/ProjectForm";
import Modal from "../../../components/Modal";
import Spinner from "../../../components/Spinner";
import Title from "../../../components/Title";
import useAuthApi from "../../../core/hooks/useAuthApi";
import useFetch from "../../../core/hooks/useFetch";
import useTitle from "../../../core/hooks/useTitle";
import {
  deleteProject,
  fetchProject,
  updateProject,
} from "../../../core/modules/projects/api";
import { route, Routes } from "../../../core/routing";

const formatData = (data) => {
  const languages = data.languages.map((language) => {
    return {
      label: language.charAt(0).toUpperCase() + language.slice(1),
      value: language,
    };
  });
  return {
    ...data,
    languages: languages,
  };
};

export const ProjectEdit = () => {
  const withAuth = useAuthApi();
  const history = useHistory();
  const [isLoadingPost, setIsLoadingPost] = useState();
  const [isLoadingDelete, setIsLoadingDelete] = useState();
  const [postError, setPostError] = useState();
  const [open, setOpen] = useState(false);
  const { id } = useParams();

  const apiCall = useCallback(() => {
    return fetchProject(id);
  }, [id]);

  const { data: project, error, isLoading } = useFetch(apiCall);

  useTitle(project ? `Edit ${project.name}` : "Projects");

  const handleSubmit = (data) => {
    setIsLoadingPost(true);
    withAuth(
      updateProject({
        ...data,
        languages: data.languages.map((language) => language.value),
      })
    )
      .then(() => {
        history.push(route(Routes.Projects.Detail, { id }));
      })
      .catch((err) => {
        setPostError(err);
        setIsLoadingPost(false);
      });
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleDelete = () => {
    setIsLoadingDelete(true);
    withAuth(deleteProject(id))
      .then(() => {
        history.push(Routes.Projects.Index);
      })
      .catch((err) => {
        setPostError(err);
        setIsLoadingDelete(false);
      });
  };

  if (isLoading) {
    return <Spinner fill size="3rem" />;
  }

  if (error || postError) {
    return <ErrorAlert error={error || postError} />;
  }

  return (
    <>
      <Title title={`Edit ${project.name}`} back />
      <ProjectForm
        initialData={formatData(project)}
        onSubmit={handleSubmit}
        onDelete={toggleOpen}
        disabled={isLoadingPost}
      />
      {open && (
        <Modal
          title="Warning!"
          onDismiss={toggleOpen}
          onDelete={handleDelete}
          loading={isLoadingDelete}
        >
          <p>Are you sure you want to delete this project?</p>
        </Modal>
      )}
    </>
  );
};
