import { NutritionForm } from "../../../../components/forms/nutritions/NutritionForm";
import Title from "../../../../components/Title";

export const NutritionalDataView = () => {
  const submit = (data) => {
    console.log(data);
  };
  return (
    <>
      <Title title={`Projectname - Nutritional data`} back />
      <NutritionForm onSubmit={submit} />
    </>
  );
};
