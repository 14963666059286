import { createHeaders } from "../../utils/api";

const fetchVariantsByProjectId = (id) => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/${id}/variants`, {
    headers: createHeaders(headers),
  });
};

const fetchVariantById = (id, query = "") => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/variant/${id}${query}`, {
    headers: createHeaders(headers),
  });
};

const createVariant = (data) => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/variants`, {
    method: "POST",
    headers: createHeaders(headers),
    body: JSON.stringify(data),
  });
};

const deleteVariant = (id) => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/variants/${id}`, {
    method: "DELETE",
    headers: createHeaders(headers),
  });
};

export {
  fetchVariantsByProjectId,
  createVariant,
  deleteVariant,
  fetchVariantById,
};
