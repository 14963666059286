import { createContext, useContext, useState } from "react";
import GuestRouting from "./Browserrouter/GuestRouting";
import MainRouting from "./Browserrouter/MainRouting";
import { getUser, storeUser } from "./core/storage";

const AuthContext = createContext();

const App = () => {
  const [auth, setAuth] = useState(getUser());

  const updateAuth = (user) => {
    storeUser(user);
    setAuth(user);
  };

  const logout = () => {
    updateAuth(null);
  };

  if (auth && auth.user) {
    const { user, token } = auth;
    return (
      <AuthContext.Provider value={{ user, token, logout, updateAuth }}>
        <MainRouting />
      </AuthContext.Provider>
    );
  }

  return <GuestRouting setUser={updateAuth} />;
};

// get context data
const useAuth = () => {
  return useContext(AuthContext);
};

export { useAuth };

export default App;
