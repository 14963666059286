import { useState } from "react";
import { useHistory } from "react-router-dom";
import ErrorAlert from "../../../components/errors/ErrorAlert";
import { TranslationForm } from "../../../components/forms/Translations/TranslationForm";
import Title from "../../../components/Title";
import useAuthApi from "../../../core/hooks/useAuthApi";
import useTitle from "../../../core/hooks/useTitle";
import { createTranslation } from "../../../core/modules/translations/api";
import { Routes } from "../../../core/routing";

export const TranslationCreate = () => {
  const withAuth = useAuthApi();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  useTitle("Translate Keyword");

  const handleSubmit = (data) => {
    setIsLoading(true);
    withAuth(createTranslation(data))
      .then(() => {
        history.push(Routes.Translations.Index);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };
  return (
    <>
      <Title title="Translate Keyword" back />
      <ErrorAlert error={error} />
      <TranslationForm onSubmit={handleSubmit} loading={isLoading} />
    </>
  );
};
