

const FormTitle = ({ title }) => {
  return (
    <>
      <h3 className="py-3" >{title}</h3>
    </>
  )
}

export default FormTitle