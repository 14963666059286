import SmallInput from "../SmallInput";

export const NutritionRow = ({
  name,
  hundred,
  portion,
  procent,
  onChange,
  title,
  portionRow = true,
  errors,
  validated,
  disabled,
}) => {
  return (
    <tr>
      <th scope="row">{title}</th>
      <td>
        <p>
          <SmallInput
            name={name.toLowerCase()}
            value={hundred}
            onChange={onChange}
          />
        </p>
      </td>
      {portionRow && (
        <td>
          <p>
            <SmallInput
              name={`${name.toLowerCase()}Portion`}
              value={portion}
              onChange={onChange}
            />
            g
          </p>
        </td>
      )}
      <td>
        <p>
          <SmallInput
            name={`${name.toLowerCase()}Procent`}
            value={procent}
            onChange={onChange}
          />
          %
        </p>
      </td>
    </tr>
  );
};
