import { useState } from "react";
import { useHistory } from "react-router";
import Title from "../../../components/Title";
import useAuthApi from "../../../core/hooks/useAuthApi";
import { createUser } from "../../../core/modules/users/api";
import useTitle from "../../../core/hooks/useTitle";
import { Routes } from "../../../core/routing";
import ErrorAlert from "../../../components/errors/ErrorAlert";
import UserForm from "../../../components/forms/Users/UserForm";

const UserCreate = () => {
  const withAuth = useAuthApi();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  useTitle("Create user");

  const handleSubmit = (data) => {
    setIsLoading(true);
    withAuth(createUser(data))
      .then(() => {
        history.push(Routes.Users.Index);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Title title="Create User" back />
      <ErrorAlert error={error} />
      <UserForm onSubmit={handleSubmit} disabled={isLoading} />
    </>
  );
};

export default UserCreate;
