import { createHeaders } from "../../utils/api";

const fetchCompanies = () => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/companies`, {
    headers: createHeaders(headers),
  });
};

const fetchCompany = (id) => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/companies/${id}`, {
    headers: createHeaders(headers),
  });
};

const createCompany = (data) => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/companies`, {
    method: "POST",
    headers: createHeaders(headers),
    body: JSON.stringify(data),
  });
};

const updateCompany = (data) => (headers) => {
  const { _id } = data;
  return fetch(`${process.env.REACT_APP_BASE_API}/companies/${_id}`, {
    method: "PATCH",
    headers: createHeaders(headers),
    body: JSON.stringify(data),
  });
};

const deleteCompany = (id) => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/companies/${id}`, {
    method: "DELETE",
    headers: createHeaders(headers),
  });
};

export {
  fetchCompanies,
  fetchCompany,
  createCompany,
  updateCompany,
  deleteCompany,
};
