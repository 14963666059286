import { useEffect, useState } from "react";
import CardTable from "../../../components/CardTable";
import TableControlPagination from "../../../components/TableControlPagination";
import { TranslationsTable } from "../../../components/translations/TranslationsTable";

export const AdditiveTableCard = ({ title, data, refresh, pageSize = 10 }) => {
  const maxPages = Math.ceil(data.length / pageSize) - 1;

  const [page, setPage] = useState(0);
  const [paginatedData, setPaginatedData] = useState();

  useEffect(() => {
    let translationsFiltered = [];

    for (let index = 0; index < pageSize; index++) {
      if (data[pageSize * page + index]) {
        translationsFiltered.push(data[pageSize * page + index]);
      }
    }

    setPaginatedData(translationsFiltered);
  }, [page, data]);

  if (!paginatedData) {
    return null;
  }

  return (
    <CardTable
      headerContent={
        <TableControlPagination
          title={title}
          page={page}
          setPage={setPage}
          maxPages={maxPages}
        />
      }
    >
      <TranslationsTable
        translations={paginatedData}
        refresh={refresh}
        additive
      />
    </CardTable>
  );
};
