import { useAuth } from "../../../App";
import Title from "../../../components/Title";
import useTitle from "../../../core/hooks/useTitle";
import ActiveProjects from "../../shared/projects/ActiveProjects";

const AdminDashboard = () => {
  useTitle("Dashboard");
  const { user } = useAuth();

  return (
    <>
      <Title title={`Dashboard | ${user.name}`} />

      <ActiveProjects />
    </>
  );
};

export default AdminDashboard;
