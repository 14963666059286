const { format } = require("date-fns");

const ProjectDataItem = ({ title, item }) => {
  return (
    <>
      <dd className="col-4">
        <b>{`${title}:`}</b>
      </dd>
      <dd className="col-8">{item}</dd>
    </>
  );
};

const ProjectData = ({ data }) => {
  return (
    <>
      <div className="row">
        <div className="col-6 border-end border-2">
          <dl className="row m-0">
            <ProjectDataItem title="Brand" item={data.brand} />
            <ProjectDataItem title="Project-manager" item={data.user.name} />
            <ProjectDataItem title="Variants" item="TODO" />
          </dl>
        </div>
        <div className="col-6">
          <dl className="row m-0">
            <ProjectDataItem
              title="Started at"
              item={format(Date.parse(data.startDate), "dd-MM-yyyy")}
            />
            <ProjectDataItem title="Budget" item={data.budget} />
            <ProjectDataItem title="Description" item={data.description} />
          </dl>
        </div>
      </div>
      <hr />
    </>
  );
};

export default ProjectData;
