import { useState } from "react";
import { useHistory } from "react-router";
import ErrorAlert from "../../../components/errors/ErrorAlert";
import CompanyForm from "../../../components/forms/Companies/CompanyForm";
import Title from "../../../components/Title";
import useAuthApi from "../../../core/hooks/useAuthApi";
import useTitle from "../../../core/hooks/useTitle";
import { createCompany } from "../../../core/modules/companies/api";
import { Routes } from "../../../core/routing";

const CompanyCreate = () => {
  const withAuth = useAuthApi();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  useTitle("Create company");

  const handleSubmit = (data) => {
    setIsLoading(true);
    withAuth(createCompany(data))
      .then(() => {
        history.push(Routes.Companies.Index);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Title title="Create a company" back />
      <ErrorAlert error={error} />
      <CompanyForm onSubmit={handleSubmit} loading={isLoading} />
    </>
  );
};

export default CompanyCreate;
