import { useCallback } from "react";
import ErrorAlert from "../../../components/errors/ErrorAlert";
import { VariantForm } from "../../../components/forms/Variants/VariantForm";
import Spinner from "../../../components/Spinner";
import VariantTable from "../../../components/variants/VariantTable";
import useFetch from "../../../core/hooks/useFetch";
import { fetchVariantsByProjectId } from "../../../core/modules/variants/api";

export const VariantsView = ({ projectId, open, toggleOpen }) => {
  const apiCall = useCallback(() => {
    return fetchVariantsByProjectId(projectId, "?section=overview");
  }, [projectId]);

  const { data: variants, error, isLoading, refresh } = useFetch(apiCall);

  if (isLoading) {
    return <Spinner height={50} />;
  }

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <div className="pb-1">
      {variants.map((variant, index) => (
        <VariantTable key={index} data={variant} refresh={refresh} />
      ))}

      {/* this is here to use the refresh of only the variants */}
      {open && (
        <VariantForm
          title="Add Variant"
          onDismiss={toggleOpen}
          projectId={projectId}
          refresh={refresh}
        />
      )}
    </div>
  );
};
