import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../App";
import { Routes } from "../../core/routing";
import avatar from "../../assets/images/avatar.png";

const ProfileBar = () => {
  const [status, setStatus] = useState(false);

  const { logout, user } = useAuth();

  const HandleOnClick = () => {
    setStatus(!status);
  };

  return (
    <div className="dropdown">
      <div
        className="d-flex align-items-center link-dark text-decoration-none dropdown-toggle"
        onClick={HandleOnClick}
      >
        <img
          src={avatar}
          alt="avatar"
          width="32"
          height="32"
          className="rounded-circle me-2"
        />
        <strong>{user.name}</strong>
      </div>

      <ul
        className={`dropdown-menu text-small shadow profile-dropdown ${
          status && "show"
        }`}
        onMouseLeave={HandleOnClick}
      >
        <li>
          <Link to={Routes.Profile} className="dropdown-item">
            Profile
          </Link>
        </li>
        <li>
          <Link to={Routes.Dashboard} className="dropdown-item">
            Settings
          </Link>
        </li>
        <li>
          <hr to={Routes.Dashboard} className="dropdown-divider" />
        </li>
        <li>
          <button
            className="dropdown-item text-danger"
            onClick={() => logout()}
          >
            Sign out
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ProfileBar;
