import { Link } from "react-router-dom";
import { Routes, route } from "../../core/routing";

const CompanyRow = ({ company }) => {
  return (
    <tr className={!company.reviewed ? "table-warning" : undefined}>
      <th scope="row" className="text-start ps-3">
        {company.name}
      </th>

      {company.reviewed ? (
        <>
          <td>{company.fullAddress}</td>
          <td>{company.email}</td>
          <td>{company.btw}</td>
        </>
      ) : (
        <td colSpan="3">Waiting for review...</td>
      )}

      <td className="pe-3">
        <Link
          to={route(Routes.Companies.Detail, { id: company._id })}
          className="btn btn-sm btn-primary float-end"
        >
          View
        </Link>
      </td>
    </tr>
  );
};

export default CompanyRow;
