import { Link } from "react-router-dom";
import { route, Routes } from "../../core/routing";
const { format } = require("date-fns");

const ProjectRow = ({ data, user }) => {
  return (
    <tr>
      <th scope="row" className="text-start ps-3">
        {data.job}
      </th>
      <td>{data.name}</td>
      {!user && <td>{data.company.name}</td>}
      <td>{data.user ? data.user.name : "No Project-leader"}</td>
      <td>{format(Date.parse(data.startDate), "dd-MM-yyyy")}</td>
      {/* {!user && (
        <td>
          <h5 className="m-0">
            {data.visible ? (
              <span className="badge rounded-pill bg-success w-100">
                Visble
              </span>
            ) : (
              <span className="badge rounded-pill bg-warning w-100">
                Invisble
              </span>
            )}
          </h5>
        </td>
      )} */}
      <td className="pe-3">
        <Link
          to={route(Routes.Projects.Detail, { id: data._id })}
          className="btn btn-sm btn-primary float-end"
        >
          Go to project
        </Link>
      </td>
    </tr>
  );
};

export default ProjectRow;
