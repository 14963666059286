import PropTypes from "prop-types";

const Button = ({
  type = "button",
  title,
  color = "primary",
  extraStyle,
  disabled,
  onClick,
  loading,
}) => {
  return (
    <button
      type={type}
      className={`btn btn-${color} ${extraStyle && extraStyle}`}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading && (
        <span
          className="spinner-border spinner-border-sm me-2"
          role="status"
          aria-hidden="true"
        ></span>
      )}
      {title}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.oneOf(["button", "reset", "submit"]),
  disabled: PropTypes.bool,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "light",
    "outline-light",
    "outline-primary",
    "outline-secondary",
    "warning",
    "danger",
    "success",
  ]),
};

export default Button;
