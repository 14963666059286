const Spinner = ({ size = "2rem", fill, height }) => {
  if (fill) {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center">
        <div
          className="spinner-grow text-primary"
          style={{ width: size, height: size }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`d-flex justify-content-center align-items-center ${
        height && `h-${height}`
      }`}
    >
      <div
        className="spinner-grow text-primary m-2"
        style={{ width: size, height: size }}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Spinner;
