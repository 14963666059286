import * as yup from "yup";
import useForm from "../../../core/hooks/useForm";
import Button from "../Button";
import FormTitle from "../FormTitle";
import Input from "../Input";

const defaultData = {
  firstName: "",
  lastName: "",
  email: "",
};

let schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
});

const UserEditForm = ({
  initialData = {},
  onSubmit,
  onEdit,
  disabled,
  loading,
}) => {
  const initial = {
    ...defaultData,
    ...initialData,
  };

  const { values, errors, handleChange, handleSubmit, isTouched } = useForm(
    schema,
    initial
  );

  const handleData = (values) => {
    const data = values;
    // if company is not Mediamind change role to client
    if (values.companyId !== "60acfaf165abe2648c46da9c") {
      data.role = "client";
    }
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleData)} noValidate>
      <FormTitle title="Userdata" />
      <Input
        label="First name"
        name="firstName"
        value={values.firstName}
        onChange={handleChange}
        error={errors.firstName}
        validated={isTouched}
        disabled={disabled || loading}
      />
      <Input
        label="Last name"
        name="lastName"
        value={values.lastName}
        onChange={handleChange}
        error={errors.lastName}
        validated={isTouched}
        disabled={disabled || loading}
      />
      <Input
        label="E-mail"
        name="email"
        value={values.email}
        onChange={handleChange}
        error={errors.email}
        validated={isTouched}
        disabled={disabled || loading}
      />

      <div className="d-grid gap-2 d-flex justify-content-md-end align-self-end mt-4">
        <Button
          type="submit"
          title="Submit"
          disabled={disabled}
          loading={loading}
        />
        {onEdit && (
          <Button
            type="button"
            title="Edit"
            color="warning"
            onClick={onEdit}
            disabled={loading}
          />
        )}
      </div>
    </form>
  );
};

export default UserEditForm;
