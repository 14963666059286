import Textarea from "../Textarea";
import * as yup from "yup";
import useForm from "../../../core/hooks/useForm";
import Button from "../Button";
import { Checkbox } from "../Checkbox";
import { useState } from "react";
import Radio from "../Radio";
import ErrorAlert from "../../errors/ErrorAlert";
import { supportedLanguages } from "../../../core/variables";

const defaultData = {
  inputLanguage: "dutch",
  languages: [],
  text: "",
  trails: "",
};

let schema = yup.object().shape({
  inputLanguage: yup.string().required(),
  languages: yup.array().required(),
  text: yup.string().required(),
  trails: yup.string(),
});

export const IngredientsInputForm = ({ data, onSubmit, loading, error }) => {
  const initial = {
    ...defaultData,
    languages: data.languages,
  };

  const { values, errors, handleChange, handleSubmit, isTouched } = useForm(
    schema,
    initial
  );

  const [checked, setChecked] = useState();

  const handleCheck = () => {
    setChecked(!checked);
  };

  const handleData = (values) => {
    onSubmit(values);
  };

  if (error) {
    return (
      <>
        <ErrorAlert error={error} />
        <hr />
      </>
    );
  }

  return (
    <form onSubmit={handleSubmit(handleData)} className="border-bottom">
      <div className="row">
        <h4>Instructions</h4>
        <p>
          Paste the ingredient list in the ‘text input’ field and check the
          language button. The final sentence on allergenes must be input
          seperately. <br /> Ingredients that are not processed correctly are
          shown in red. Please add translation suggestions in the comments
          field.
        </p>
      </div>
      <div className="row pt-2">
        <div className="col-8 pb-0">
          <p className="m-0">Ingredients:</p>
          <fieldset className="grid">
            {supportedLanguages.map((language, index) => {
              if (data.languages.includes(language)) {
                return (
                  <Radio
                    inline
                    label={language}
                    key={index}
                    name="inputLanguage"
                    value={language}
                    onChange={handleChange}
                    error={errors.inputLanguage}
                    validated={isTouched}
                    disabled={loading}
                    selected={values.inputLanguage}
                  />
                );
              } else {
                return null;
              }
            })}
          </fieldset>
          <Textarea
            rows="8"
            name="text"
            value={values.text}
            onChange={handleChange}
            error={errors.text}
            validated={isTouched}
            disabled={loading}
          />
        </div>
        <div className="col-4 pt-4">
          <Checkbox
            label="Kan sporen van"
            name="trailsCheck"
            checked={checked}
            onChange={handleCheck}
            error={errors.trailsCheck}
            validated={isTouched}
            disabled={loading}
          />
          <Textarea
            rows="4"
            name="trails"
            value={values.trails}
            onChange={handleChange}
            error={errors.trails}
            validated={isTouched}
            disabled={!checked}
          />
          <p className="text-end m-0">bevatten</p>
        </div>
      </div>
      <div className="col mb-2">
        <Button type="submit" title="Translate" loading={loading} />
      </div>
    </form>
  );
};
