import ErrorAlert from "../../../components/errors/ErrorAlert";
import Spinner from "../../../components/Spinner";
import Title from "../../../components/Title";
import UserTable from "../../../components/users/UserTable";
import useFetch from "../../../core/hooks/useFetch";
import useTitle from "../../../core/hooks/useTitle";
import { fetchUsers } from "../../../core/modules/users/api";

const UserOverview = () => {
  useTitle("Users");

  const { data: users, error, isLoading } = useFetch(fetchUsers);

  if (isLoading) {
    return <Spinner fill size="3rem" />;
  }

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <>
      <Title title="Users" />
      <UserTable users={users} />
    </>
  );
};

export default UserOverview;
