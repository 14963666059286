import { createHeaders } from "../../utils/api";

const fetchProjects = (query = "") => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/projects${query}`, {
    headers: createHeaders(headers),
  });
};

const fetchProject = (id) => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/projects/${id}`, {
    headers: createHeaders(headers),
  });
};

const fetchProjectsByCompanyId = (companyId) => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/${companyId}/projects`, {
    headers: createHeaders(headers),
  });
};

const createProject = (data) => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/projects`, {
    method: "POST",
    headers: createHeaders(headers),
    body: JSON.stringify(data),
  });
};

const updateProject = (data) => (headers) => {
  const { _id } = data;
  return fetch(`${process.env.REACT_APP_BASE_API}/projects/${_id}`, {
    method: "PATCH",
    headers: createHeaders(headers),
    body: JSON.stringify(data),
  });
};

const deleteProject = (id) => (headers) => {
  return fetch(`${process.env.REACT_APP_BASE_API}/projects/${id}`, {
    method: "DELETE",
    headers: createHeaders(headers),
  });
};

export {
  fetchProjects,
  createProject,
  fetchProjectsByCompanyId,
  fetchProject,
  updateProject,
  deleteProject,
};
