import { useCallback } from "react";
import { useParams } from "react-router";
import ErrorAlert from "../../../components/errors/ErrorAlert";
import ProjectTable from "../../../components/projects/ProjectTable";
import Spinner from "../../../components/Spinner";
import useFetch from "../../../core/hooks/useFetch";
import { fetchProjectsByCompanyId } from "../../../core/modules/projects/api";

const CompanyProjects = () => {
  const { id } = useParams();

  const apiCall = useCallback(() => {
    return fetchProjectsByCompanyId(id);
  }, [id]);

  const { data: projects, error, isLoading } = useFetch(apiCall);

  if (isLoading) {
    return <Spinner height={25} />;
  }

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return <ProjectTable projects={projects} title="Projects" />;
};

export default CompanyProjects;
