import * as yup from "yup";
import { useEffect, useState } from "react";
import useForm from "../../../core/hooks/useForm";
import Input from "../Input";
import Button from "../Button";
import useAuthApi from "../../../core/hooks/useAuthApi";
import { createVariant } from "../../../core/modules/variants/api";
import ErrorAlert from "../../errors/ErrorAlert";

const defaultData = {
  name: "",
  projectId: "",
};

let schema = yup.object().shape({
  name: yup.string().required(),
  projectId: yup.string().required(),
});

export const VariantForm = ({
  title,
  onDismiss,
  initialData,
  projectId,
  refresh,
}) => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  const withAuth = useAuthApi();
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  const initial = {
    ...defaultData,
    ...initialData,
    projectId: projectId,
  };

  const { values, errors, handleChange, handleSubmit, isTouched } = useForm(
    schema,
    initial
  );

  const handleData = (values) => {
    setIsLoading(true);
    withAuth(
      createVariant({
        ...values,
      })
    )
      .then(() => {
        refresh();
        onDismiss();
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };

  return (
    <>
      <ErrorAlert error={error} />
      <div
        className="modal fade show"
        style={{ display: "block" }}
        id="staticBackdrop"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {title}
              </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={onDismiss}
              />
            </div>

            <div className="modal-body">
              <form onSubmit={handleSubmit(handleData)} noValidate>
                <Input
                  label="Variant name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  error={errors.name}
                  validated={isTouched}
                  disabled={isLoading}
                />
                <Button type="submit" title="Submit" loading={isLoading} />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};
