const Radio = ({
  name,
  label,
  onChange,
  value,
  selected,
  error,
  validated,
  disabled,
  inline,
}) => {
  return (
    <div className={`form-check ${inline && "form-check-inline"}`}>
      <input
        className={`form-check-input ${
          validated ? (error ? "is-invalid" : "is-valid") : ""
        }`}
        type="radio"
        id={name}
        name={name}
        onChange={onChange}
        value={value}
        disabled={disabled}
        defaultChecked={selected === value && "checked"}
      />
      {label && (
        <label htmlFor={name} className="form-check-label">
          {label}
        </label>
      )}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default Radio;
