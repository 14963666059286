import { Redirect, Route, Switch } from "react-router";
import { Routes } from "../core/routing";
import Login from "../pages/Login/Login";

const GuestRouting = ({ setUser }) => {
  return (
    <Switch>
      <Route path={Routes.Login}>
        <Login setUser={setUser} />
      </Route>
      <Redirect to={Routes.Login} />
    </Switch>
  );
};

export default GuestRouting;
