import { Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "../core/routing";
import CompanyCreate from "../pages/admin/companies/CompanyCreate";
import CompanyDetail from "../pages/admin/companies/CompanyDetail";
import CompanyOverview from "../pages/admin/companies/CompanyOverview";
import AdminDashboard from "../pages/admin/dashboard/AdminDashboard";
import ProjectCreate from "../pages/admin/projects/ProjectCreate";
import ProjectDetail from "../pages/admin/projects/ProjectDetail";
import { ProjectEdit } from "../pages/admin/projects/ProjectEdit";
import ProjectOverview from "../pages/admin/projects/ProjectOverview";
import { TranslationCreate } from "../pages/admin/translations/TranslationCreate";
import { TranslationEdit } from "../pages/admin/translations/TranslationEdit";
import { TranslationsOverview } from "../pages/admin/translations/TranslationsOverview";
import UserCreate from "../pages/admin/users/UserCreate";
import UserDetail from "../pages/admin/users/UserDetail";
import UserOverview from "../pages/admin/users/UserOverview";
import Profile from "../pages/shared/Profile";
import { GeneralDataView } from "../pages/shared/variants/generalData/GeneralDataView";
import { IngredientsView } from "../pages/shared/variants/ingredients/IngredientsView";
import { NutritionalDataView } from "../pages/shared/variants/nutritionalData/NutritionalDataView";
import { ServingView } from "../pages/shared/variants/serving/ServingView";

const AdminRouting = () => {
  return (
    <Switch>
      <Route path={Routes.Profile}>
        <Profile />
      </Route>

      {/* Dashboard */}
      <Route path={Routes.Dashboard}>
        <AdminDashboard />
      </Route>

      {/* Companies */}
      <Route path={Routes.Companies.Create}>
        <CompanyCreate />
      </Route>
      <Route path={Routes.Companies.Detail}>
        <CompanyDetail />
      </Route>
      <Route path={Routes.Companies.Index}>
        <CompanyOverview />
      </Route>

      {/* Users */}
      <Route path={Routes.Users.Create}>
        <UserCreate />
      </Route>
      <Route path={Routes.Users.Edit}>
        <UserDetail />
      </Route>
      <Route path={Routes.Users.Index}>
        <UserOverview />
      </Route>

      {/* Variants */}
      <Route path={Routes.Variants.Ingredients}>
        <IngredientsView />
      </Route>
      <Route path={Routes.Variants.General}>
        <GeneralDataView />
      </Route>
      <Route path={Routes.Variants.Nutritional}>
        <NutritionalDataView />
      </Route>
      <Route path={Routes.Variants.Serving}>
        <ServingView />
      </Route>

      {/* Projects */}
      <Route path={Routes.Projects.Create}>
        <ProjectCreate />
      </Route>
      <Route path={Routes.Projects.Edit}>
        <ProjectEdit />
      </Route>
      <Route path={Routes.Projects.Detail}>
        <ProjectDetail />
      </Route>
      <Route path={Routes.Projects.Index}>
        <ProjectOverview />
      </Route>

      {/* Translations */}
      <Route path={Routes.Translations.Edit}>
        <TranslationEdit />
      </Route>
      <Route path={Routes.Translations.Create}>
        <TranslationCreate />
      </Route>
      <Route path={Routes.Translations.Index}>
        <TranslationsOverview />
      </Route>

      <Redirect to={Routes.Dashboard} />
    </Switch>
  );
};
export default AdminRouting;
