import { useCallback, useState } from "react";
import { useAuth } from "../../App";
import ErrorAlert from "../../components/errors/ErrorAlert";
import PasswordForm from "../../components/forms/Users/PasswordForm";
import UserEditForm from "../../components/forms/Users/UserEditForm";
import Spinner from "../../components/Spinner";
import Title from "../../components/Title";
import useAuthApi from "../../core/hooks/useAuthApi";
import useFetch from "../../core/hooks/useFetch";
import { fetchUser, updateUser } from "../../core/modules/users/api";

const Profile = () => {
  const { user } = useAuth();

  const withAuth = useAuthApi();

  const [edit, setEdit] = useState(true);
  const [validationError, setValidationError] = useState();
  const [isLoadingPost, setisLoadingPost] = useState();

  const apiCall = useCallback(() => {
    return fetchUser(user._id);
  }, [user._id]);

  const { data: userData, error, isLoading, refresh, setData } = useFetch(
    apiCall
  );

  const handleSubmit = (data) => {
    setisLoadingPost(true);
    withAuth(updateUser(data))
      .then(() => {
        setData(null);
        refresh();
        setEdit(true);
        setisLoadingPost(false);
      })
      .catch((err) => {
        setValidationError(err);
        setisLoadingPost(false);
      });
  };

  const handleEdit = (data) => {
    setEdit(!edit);
  };

  if (isLoading) {
    return <Spinner fill size="5rem" />;
  }

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <>
      <Title title={userData.name} />
      <div className="row">
        <div className="col-6 border-end border-2">
          {validationError && <ErrorAlert error={validationError} />}
          <UserEditForm
            initialData={userData}
            onSubmit={handleSubmit}
            onEdit={handleEdit}
            disabled={edit}
            loading={isLoadingPost}
          />
        </div>
        <div className="col-6">
          <PasswordForm />
        </div>
      </div>
    </>
  );
};

export default Profile;
