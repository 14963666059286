import ProjectForm from "../../../components/forms/Projects/ProjectForm";
import Title from "../../../components/Title";
import { createProject } from "../../../core/modules/projects/api";
import useTitle from "../../../core/hooks/useTitle";
import useAuthApi from "../../../core/hooks/useAuthApi";
import { useHistory } from "react-router";
import { useState } from "react";
import { Routes } from "../../../core/routing";
import ErrorAlert from "../../../components/errors/ErrorAlert";

const ProjectCreate = () => {
  const withAuth = useAuthApi();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  useTitle("Create project");

  const handleSubmit = (data) => {
    setIsLoading(true);
    withAuth(
      createProject({
        ...data,
        languages: data.languages.map((language) => language.value),
      })
    )
      .then(() => {
        history.push(Routes.Projects.Index);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };
  return (
    <>
      <Title title="Create project" back />
      <ErrorAlert error={error} />
      <ProjectForm onSubmit={handleSubmit} disabled={isLoading} />
    </>
  );
};

export default ProjectCreate;
