import { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router";
import ErrorAlert from "../../../components/errors/ErrorAlert";
import Modal from "../../../components/Modal";
// import ProjectTable from "../../../components/projects/ProjectTable";
import Spinner from "../../../components/Spinner";
import Title from "../../../components/Title";
import useAuthApi from "../../../core/hooks/useAuthApi";
import useFetch from "../../../core/hooks/useFetch";
import useTitle from "../../../core/hooks/useTitle";
import {
  deleteCompany,
  fetchCompany,
  updateCompany,
} from "../../../core/modules/companies/api";
import { Routes } from "../../../core/routing";
import CompanyForm from "../../../components/forms/Companies/CompanyForm";
import CompanyProjects from "./CompanyProjects";

// const projects = [
//   {
//     jobnummer: 5641651,
//     name: "Garnalen Aldi",
//     projectLeader: "Emiel De Vleeschouwer",
//     date: "23/07/2020",
//     _id: 123456,
//   },
//   {
//     jobnummer: 9684531,
//     name: "Garnalen Aldi",
//     projectLeader: "Jørn Broekaert",
//     date: "12/04/2020",
//     _id: 666555,
//   },
//   {
//     jobnummer: 9684531,
//     name: "Garnalen Aldi",
//     projectLeader: "Jørn Broekaert",
//     date: "12/04/2020",
//     _id: 666555,
//   },
//   {
//     jobnummer: 5641651,
//     name: "Garnalen Aldi",
//     projectLeader: "Emiel De Vleeschouwer",
//     date: "23/07/2020",
//     _id: 123456,
//   },
//   {
//     jobnummer: 9684531,
//     name: "Garnalen Aldi",
//     projectLeader: "Jørn Broekaert",
//     date: "12/04/2020",
//     _id: 666555,
//   },
// ];

const CompanyDetail = () => {
  const history = useHistory();

  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [validationError, setValidationError] = useState();
  const [isLoadingPost, setIsLoadingPost] = useState();
  const [isLoadingDelete, setIsLoadingDelete] = useState();

  const { id } = useParams();

  const withAuth = useAuthApi();

  const apiCall = useCallback(() => {
    return fetchCompany(id);
  }, [id]);

  const { data: company, error, isLoading, setData, refresh } = useFetch(
    apiCall
  );

  useTitle(company ? company.name : "Companies");

  const toggleEdit = () => {
    setEdit(!edit);
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleSubmit = (data) => {
    setIsLoadingPost(true);
    withAuth(updateCompany(data))
      .then(() => {
        setData(null);
        refresh();
        setEdit(false);
        setIsLoadingPost(false);
      })
      .catch((err) => {
        setValidationError(err);
        setIsLoadingPost(false);
      });
  };

  const handleDelete = () => {
    setIsLoadingDelete(true);
    withAuth(deleteCompany(id))
      .then(() => {
        history.push(Routes.Companies.Index);
      })
      .catch((err) => {
        setValidationError(err);
        setIsLoadingDelete(false);
      });
  };

  if (isLoading) {
    return <Spinner fill size="3rem" />;
  }

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <>
      <Title title={company.name} back />
      {validationError && <ErrorAlert error={validationError} />}
      <CompanyForm
        onSubmit={handleSubmit}
        initialData={company}
        disabled={!edit}
        loading={isLoadingPost}
        onEdit={toggleEdit}
        onDelete={toggleOpen}
      />

      <hr />

      <CompanyProjects />

      {open && (
        <Modal
          title="Warning!"
          onDismiss={toggleOpen}
          onDelete={handleDelete}
          loading={isLoadingDelete}
        >
          <p>Are you sure you want to delete this company?</p>
        </Modal>
      )}
    </>
  );
};

export default CompanyDetail;
