import { useAuth } from "../App";
import Sidebar from "../components/sidebar/Sidebar";
import Confirmation from "../pages/confirmation/Confirmation";
import AdminRouting from "./AdminRouting";
import ClientRouting from "./ClientRouting";
import DesignerRouting from "./DesignerRouting";

const MainRouting = () => {
  const { user } = useAuth();

  if (!user.company.reviewed) {
    return <Confirmation />;
  }

  return (
    <>
      <Sidebar />

      <div className="content">
        {user.role === "admin" && <AdminRouting />}
        {user.role === "client" && <ClientRouting />}
        {user.role === "designer" && <DesignerRouting />}
      </div>
    </>
  );
};
export default MainRouting;
