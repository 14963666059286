import { Routes } from "../../core/routing";
import CardTable from "../CardTable";
import TableControl from "../TableControl";
import CompanyRow from "./CompanyRow";

const CompanyTable = ({ companies }) => {
  return (
    <CardTable
      headerContent={
        <TableControl name="company" link={Routes.Companies.Create} table />
      }
    >
      <thead>
        <tr>
          <th scope="col" className="text-start ps-3">
            Name
          </th>
          <th scope="col">Address</th>
          <th scope="col">E-mail</th>
          <th scope="col">BTW</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        {companies.map((company, index) => (
          <CompanyRow company={company} key={index} />
        ))}
      </tbody>
    </CardTable>
  );
};

export default CompanyTable;
