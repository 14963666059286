import CardTable from "../CardTable";
import ProjectRow from "./ProjectRow";

const ProjectTable = ({ projects, title, user }) => {
  if (projects.length === 0) {
    return null;
  }
  return (
    <CardTable title={title}>
      <thead>
        <tr>
          <th scope="col" className="text-start ps-3">
            MM jobnummer
          </th>
          <th scope="col">Name</th>
          {!user && <th scope="col">Company</th>}
          <th scope="col">Project-leader</th>
          <th scope="col">Start-date</th>
          {/* {!user && <th>Visible</th>} */}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {projects.map((project, index) => (
          <ProjectRow data={project} key={index} user={user} />
        ))}
      </tbody>
    </CardTable>
  );
};

export default ProjectTable;
