import PropTypes from "prop-types";
import { useHistory } from "react-router";

const Title = ({ title, back }) => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="m-0">{title}</h1>
        {back && (
          <i
            className="h1 bi bi-arrow-left-square-fill m-0 text-primary pointer"
            onClick={goBack}
          ></i>
        )}
      </div>
      <hr />
    </>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Title;
