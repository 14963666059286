import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const SidebarLink = ({ name, icon, link }) => {
  return (
    <li className="nav-item">
      <NavLink to={link} className="nav-link link-dark">
        <i className={`bi bi-${icon} me-2`}></i>
        {name}
      </NavLink>
    </li>
  );
};

SidebarLink.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default SidebarLink;
