import * as yup from "yup";
import useForm from "../../../core/hooks/useForm";
import Button from "../Button";
import Input from "../Input";
import Select from "../Select";
import UserSelectCompany from "./UserSelectCompany";

const defaultData = {
  firstName: "",
  lastName: "",
  companyId: "",
  role: "client",
  email: "",
};

const roles = [
  { name: "Admin", id: "admin" },
  { name: "Client", id: "client" },
  { name: "Designer", id: "designer" },
];

let schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  companyId: yup
    .string()
    .required()
    .test("not-default", "Please select a company", (value) => value !== "--"),
  role: yup
    .string()
    .required()
    .test(
      "not-default",
      "Please select a valid role",
      (value) => value !== "--"
    ),
  email: yup.string().email().required(),
});

const UserEditFormAdmin = ({
  initialData = {},
  onSubmit,
  onDelete,
  disabled,
}) => {
  const initial = {
    ...defaultData,
    ...initialData,
  };

  const { values, errors, handleChange, handleSubmit, isTouched } = useForm(
    schema,
    initial
  );

  const handleData = (values) => {
    const data = values;
    // if company is not Mediamind change role to client
    if (values.companyId !== "60acfaf165abe2648c46da9c") {
      data.role = "client";
    }
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleData)} noValidate>
      <div className="row">
        <div className="col-6 border-end border-2">
          <Input
            label="First name"
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            error={errors.firstName}
            validated={isTouched}
            disabled={disabled}
          />
          <Input
            label="Last name"
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            error={errors.lastName}
            validated={isTouched}
            disabled={disabled}
          />
          <UserSelectCompany
            label="Company"
            name="companyId"
            value={values.companyId}
            onChange={handleChange}
            error={errors.companyId}
            validated={isTouched}
            disabled={disabled}
          />
          {values.companyId === "60acfaf165abe2648c46da9c" && (
            <Select
              label="Role"
              name="role"
              data={roles}
              value={values.role}
              onChange={handleChange}
              error={errors.role}
              validated={isTouched}
              disabled={disabled}
            />
          )}
          <Input
            label="E-mail"
            name="email"
            value={values.email}
            onChange={handleChange}
            error={errors.email}
            validated={isTouched}
            disabled={disabled}
          />

          <div className="d-grid gap-2 d-flex justify-content-md-end align-self-end mt-4">
            <Button type="submit" title="Submit" loading={disabled} />
            {onDelete && (
              <Button
                type="button"
                title="Delete"
                color="danger"
                onClick={onDelete}
                disabled={disabled}
              />
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default UserEditFormAdmin;
