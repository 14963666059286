import Button from "../Button";
import Textarea from "../Textarea";
import * as yup from "yup";
import useForm from "../../../core/hooks/useForm";

let schema = yup.object().shape({
  data: yup
    .array()
    .of(
      yup
        .object({
          name: yup.string().required(),
          text: yup.string().required("Textfield can't be empty"),
        })
        .required()
    )
    .required(),
});

export const IngredientsTranslateForm = ({
  initialData,
  languages,
  loading,
  onSubmit,
}) => {
  const defaultData = {
    data: languages.map((language) => {
      return {
        name: language,
        text: "",
      };
    }),
  };

  const initial = {
    ...defaultData,
    data: initialData !== null ? initialData.data : defaultData.data,
  };

  const { values, errors, handleSubmit, isTouched, setValues } = useForm(
    schema,
    initial
  );

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getValue = (language) => {
    const index = values.data.findIndex((x) => x.name === language);

    if (values.data[index]) {
      return values.data[index].text;
    } else {
      return "";
    }
  };

  const handleChange = (e) => {
    const index = values.data.findIndex((x) => x.name === e.target.name);
    let changedData = { ...values };
    changedData.data[index].text = e.target.value;
    setValues(changedData);
  };

  const handleData = () => {
    onSubmit(values);
  };

  return (
    <form
      onSubmit={handleSubmit(handleData)}
      className="mt-4 d-flex flex-column"
    >
      <div className="row">
        {languages.map((language, index) =>
          language === "dutch" ||
          language === "french" ||
          language === "german" ? (
            <div className="form-group mb-2 col-6 fakeTextArea" key={index}>
              <p>{capitalizeFirstLetter(language)}</p>
              <div
                dangerouslySetInnerHTML={{ __html: getValue(language) }}
              ></div>
            </div>
          ) : (
            <div className="col-6" key={index}>
              <Textarea
                label={capitalizeFirstLetter(language)}
                rows="8"
                key={index}
                name={language}
                value={getValue(language)}
                onChange={handleChange}
                error={errors[`data[${index}].text`]}
                validated={isTouched}
                disabled={
                  loading ||
                  language === "dutch" ||
                  language === "french" ||
                  language === "german"
                }
              />
            </div>
          )
        )}
        {initialData?.missing?.length > 0 && (
          <div className="form-group mb-2 col-6 fakeTextArea">
            <p>Missing Ingredients:</p>
            <div
              className="border-danger"
              style={{ backgroundColor: "#dc34455a" }}
            >
              <ul className="row">
                {initialData.missing.map((word, index) => (
                  <li key={index} className="col-6">
                    {word}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      <div className="mb-2 row">
        <Button type="submit" title="Submit" loading={loading} />
      </div>
    </form>
  );
};
