export const Pagination = ({ increase, decrease, page, maxPages }) => {
  const increaseDisabled = page >= maxPages - 1;
  const decreaseDisabled = page <= 0;

  return (
    <nav>
      <ul className="pagination justify-content-end mb-0 ms-3">
        <li className={`page-item ${decreaseDisabled && "disabled"}`}>
          <button
            className="page-link"
            aria-label="Next"
            type="button"
            onClick={decrease}
          >
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        <li className={`page-item ${increaseDisabled && "disabled"}`}>
          <button
            className="page-link"
            aria-label="Next"
            type="button"
            onClick={increase}
          >
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};
