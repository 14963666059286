import { useState } from "react";
import ApiError from "../../core/error/ApiError";
import AppError from "../../core/error/AppError";
import { login } from "../../core/modules/auth/api";
import { handleApiResult } from "../../core/utils/api";
import LoginForm from "./LoginForm";
import logo from "../../assets/images/mm-logo.png";
import useTitle from "../../core/hooks/useTitle";

const Login = ({ setUser }) => {
  const [error, setError] = useState();

  useTitle("Login");

  const handleOnSubmit = (data) => {
    login(data)
      .then(handleApiResult)
      .then((data) => {
        setUser(data);
      })
      .catch((e) => {
        if (e instanceof ApiError) {
          if (e.isUnauthorized()) {
            setError(new AppError("Wrong combination"));
          } else {
            setError(e);
          }
        }
        setError(new AppError(e));
      });
  };

  return (
    <main className="login text-center">
      <div className="form-signin">
        <img className="mb-4" src={logo} alt="" width="90%" />

        <LoginForm onSubmit={handleOnSubmit} validationError={error} />
      </div>
    </main>
  );
};

export default Login;
